


import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";

import MainHome from './page/BootsEmpire/MainHome'
import ContactUs from "./page/BootsEmpire/ContactUs";
import AboutUs from "./page/BootsEmpire/AboutUs";
import TermsConditions from "./page/BootsEmpire/TermsConditions";
import PrivacyPolicy from "./page/BootsEmpire/PrivacyPolicy";
import VideoGallery from "./page/BootsEmpire/VideoGallery";
import FAQPage from "./page/BootsEmpire/FAQ";
import PhotoGallery from "./page/BootsEmpire/PhotoGallery";
import Blogs from "./page/BootsEmpire/Blogs";
import Client from "./page/BootsEmpire/Client";
import TradingPage from "./page/BootsEmpire/TradingPage";
import CommercialResidentialInteriors from "./page/BootsEmpire/CommercialResidentialInteriors";
import ContractingWork from "./page/BootsEmpire/ContractingWork";
import MechanicalWorks from "./page/BootsEmpire/MechanicalWorks";
import ScrollToTop from "./components/ScrollToTop";
import ElectricalMaintenance from "./page/BootsEmpire/ElectricalMaintenance";
import PlumbingServices from "./page/BootsEmpire/PlumbingServices";
import ACServices from "./page/BootsEmpire/ACServices";
import BuildingMaintenance from "./page/BootsEmpire/BuildingMaintenance";
import WaterproofingSolutions from "./page/BootsEmpire/WaterproofingSolutions";
import PaintingServices from "./page/BootsEmpire/PaintingServices";
import GypsumFloorTilesWork from "./page/BootsEmpire/GypsumFloorTilesWork";
import ErrorPage from "./page/BootsEmpire/ErrorPage";
import DeepCleaningServices from "./page/BootsEmpire/DeepCleaningServices";
import SofaCarpetCleaning from "./page/BootsEmpire/SofaCarpetCleaning";
import PestControlServices from "./page/BootsEmpire/PestControlServices";
import GreaseTrapCleaning from "./page/BootsEmpire/GreaseTrapCleaning";
import WaterTankCleaning from "./page/BootsEmpire/WaterTankCleaning";
import CarWashServices from "./page/BootsEmpire/CarWashServices";
import KitchenDuctCleaning from "./page/BootsEmpire/KitchenDuctCleaning";
import PostConstructionCleaning from "./page/BootsEmpire/PostConstructionCleaning";

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <BrowserRouter>
        <div className="top-to-btm">
          {showTopBtn && (
            <FaAngleUp
              className="icon-position icon-style "
              style={{ zIndex: 199 }}
              onClick={goToTop}
            />
          )}
        </div>

        <ScrollToTop />

        <Routes>

          {/* Boots Empire */}

          <Route path='/' element={<MainHome />} />
          <Route path='/contact_us' element={<ContactUs />} />
          <Route path='/about_us' element={<AboutUs />} />
          <Route path='/terms_conditions' element={<TermsConditions />} />
          <Route path='/privacy_policy' element={<PrivacyPolicy />} />
          <Route path='/video_gallery' element={<VideoGallery />} />
          <Route path='/FAQ' element={<FAQPage />} />
          <Route path='/photo_gallery' element={<PhotoGallery />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/client' element={<Client />} />
          <Route path='/trading' element={<TradingPage />} />
          <Route path='/interior' element={<CommercialResidentialInteriors />} />
          <Route path='/contracting_work' element={<ContractingWork />} />
          <Route path='/mechanical_works' element={<MechanicalWorks />} />
          <Route path='/electrical_maintenance' element={<ElectricalMaintenance />} />
          <Route path='/plumbing_services' element={<PlumbingServices />} />
          <Route path='/AC_services' element={<ACServices />} />
          <Route path='/building_maintenance' element={<BuildingMaintenance />} />
          <Route path='/waterproofing_solutions' element={<WaterproofingSolutions />} />
          <Route path='/painting_services' element={<PaintingServices />} />
          <Route path='/gypsum_floor_tiles_work' element={<GypsumFloorTilesWork />} />
          <Route path='/grease_trap_cleaning' element={<GreaseTrapCleaning />} />
          <Route path='/deep_cleaning_services' element={<DeepCleaningServices />} />
          
          <Route path='/sofa_carpet_cleaning' element={<SofaCarpetCleaning />} />
          <Route path='/pest_control_services' element={<PestControlServices />} />
          <Route path='/water_tank_cleaning' element={<WaterTankCleaning />} />
          <Route path='/car_wash_services' element={<CarWashServices />} />
          <Route path='/kitchen_duct_cleaning' element={<KitchenDuctCleaning />} />
          <Route path='/post_construction_cleaning' element={<PostConstructionCleaning />} />


          <Route path='*' element={<ErrorPage />} />


        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
