import comingsoon1 from '../../assest/BootsEmpire/DeepCleaningServicesImg1.jpg'
import comingsoon2 from '../../assest/BootsEmpire/DeepCleaningServicesImg2.jpeg'
import comingsoon3 from '../../assest/BootsEmpire/DeepCleaningServicesImg3.jpeg'
import comingsoon4 from '../../assest/BootsEmpire/DeepCleaningServicesImg4.jpeg'
import comingsoon5 from '../../assest/BootsEmpire/DeepCleaningServicesImg5.jpeg'
import comingsoon6 from '../../assest/BootsEmpire/DeepCleaningServicesImg6.jpeg'
import comingsoon7 from '../../assest/BootsEmpire/DeepCleaningServicesImg7.jpeg'
import comingsoon8 from '../../assest/BootsEmpire/DeepCleaningServicesImg8.jpeg'
import comingsoon9 from '../../assest/BootsEmpire/DeepCleaningServicesImg9.jpeg'
import comingsoon10 from '../../assest/BootsEmpire/DeepCleaningServicesImg10.jpg'



const DeepCleaningServicesImageData = [
    {
        id: 1,
        src: comingsoon10,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon1,
    }, {
        id: 4,
        src: comingsoon4,
    }
    , {
        id: 5,
        src: comingsoon5,
    },
    {
        id: 6,
        src: comingsoon6,
    },
    {
        id: 7,
        src: comingsoon7,
    },
    {
        id: 8,
        src: comingsoon8,
    }, {
        id: 9,
        src: comingsoon9,
    }
    , {
        id: 10,
        src: comingsoon3,
    }



];
export default DeepCleaningServicesImageData;