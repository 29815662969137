


import comingsoon1 from '../../assest/BootsEmpire/ContractingWorkImg1.jpg'
import comingsoon2 from '../../assest/BootsEmpire/ContractingWorkImg2.jpg'
import comingsoon3 from '../../assest/BootsEmpire/ContractingWorkImg3.webp'
import comingsoon4 from '../../assest/BootsEmpire/ContractingWorkImg4.webp'
import comingsoon5 from '../../assest/BootsEmpire/ContractingWorkImg5.jpg'
import comingsoon6 from '../../assest/BootsEmpire/ContractingWorkImg6.jpg'






const ContractingWorkImgeData = [
    {
        id: 1,
        src: comingsoon3,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon1,
    }, {
        id: 4,
        src: comingsoon4,
    }
    , {
        id: 5,
        src: comingsoon5,
    }
    , {
        id: 6,
        src: comingsoon6,
    }
   
    
];
export default ContractingWorkImgeData;