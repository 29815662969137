import AVTR1 from '../../assest/BootsEmpire/3.png'

const ClientTestimonialData = [
    {
        id: 1,
        name: "Mohd Bilal",
        review:
            "Wonderful work done. Satisfied and I will call you soon for tank cleaning service.",
        image: AVTR1
    },
    {
        id: 2,
        name: "Mohd Bilal",
        review:
            "Wonderful work done. Satisfied and I will call you soon for tank cleaning service.",
        image: AVTR1
    },
    {
        id: 3,
        name: "Mohd Bilal",
        review:
            "Wonderful work done. Satisfied and I will call you soon for tank cleaning service.",
        image: AVTR1
    },
    {
        id: 4,
        name: "Mohd Bilal",
        review:
            "Wonderful work done. Satisfied and I will call you soon for tank cleaning service.",
        image: AVTR1
    },
    {
        id: 5,
        name: "Mohd Bilal",
        review:
            "Wonderful work done. Satisfied and I will call you soon for tank cleaning service.",
        image: AVTR1
    },
    {
        id: 6,
        name: "Mohd Bilal",
        review:
            "Wonderful work done. Satisfied and I will call you soon for tank cleaning service.",
        image: AVTR1
    }
];

export default ClientTestimonialData