

import people1 from '../../assest/BootsEmpire/youth.png'
import people2 from '../../assest/BootsEmpire/thumbs-up.png'
import people3 from '../../assest/BootsEmpire/eco-friendly-fabric.png'
import people4 from '../../assest/BootsEmpire/profit.png'




const ClientSatisfactionData = [
    {
        id: 1,
        heading: "Over 20,000 cleans",
        imgSrc: people1,
    },

    {
        id: 2,
        heading: "100% Satisfaction",
        imgSrc: people2,
    },
    {
        id: 3,
        heading: "Eco-Friendly",
        imgSrc: people3,
    },
    {
        id: 4,
        heading: "Cost Effective",
        imgSrc: people4,
    }
];
export default ClientSatisfactionData