const BuildingMaintenanceServicesData = [
    {
        id: 1,
        heading: " Comprehensive Maintenance Solutions",
        textdetail: "We offer a comprehensive range of building maintenance services to cater to all aspects of your property. From electrical and plumbing repairs to HVAC maintenance, painting, flooring, and more, our skilled technicians possess the expertise to handle diverse maintenance requirements.",
    },

    {
        id: 2,
         heading: " Experience and Expertise",
        textdetail: "With years of experience in the industry, we have built a strong reputation for delivering top-notch maintenance services. Our team comprises highly skilled professionals who are well-versed in the latest techniques, industry standards, and best practices. Rest assured, your property will be in the hands of experts.",
    },
    {
        id: 3,
         heading: " Customized Maintenance Plans",
        textdetail: "We understand that each building has unique maintenance needs. That's why we offer tailored maintenance plans that align with your specific requirements and budget. Our team will collaborate closely with you to design a maintenance schedule that optimizes the longevity and efficiency of your property.",
    },
    {
        id: 4,
         heading: " Timely and Efficient Service",
        textdetail: "At Boost Empire General Trading & Contracting Co. W.L.L, we value your time and strive to minimize disruptions to your operations. Our dedicated technicians are punctual, efficient, and equipped with state-of-the-art tools and equipment to ensure timely completion of projects without compromising on quality.",
    },
    {
        id: 5,
         heading: " Quality Assurance",
        textdetail: "We are committed to delivering excellence in every project we undertake. Our team adheres to stringent quality control measures, utilizing premium materials, and employing meticulous craftsmanship. With us, you can be confident in receiving superior results that meet and exceed your expectations.",
    },
    {
        id: 6,
         heading: " Exceptional Customer Service",
        textdetail: "Your satisfaction is our priority. We pride ourselves on delivering personalized customer service and maintaining open lines of communication throughout the maintenance process. Our friendly and knowledgeable team is always ready to address your queries, concerns, and emergencies promptly.",
    },

];
export default BuildingMaintenanceServicesData