
import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo2 from '../../assest/BootsEmpire/DeepCleaningServicesImg1.jpg'
import photo1 from '../../assest/BootsEmpire/carpet-cleaning.webp'
import photo3 from '../../assest/BootsEmpire/DeepCleaningServicesBanner.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import ElectricalServicesData from "../../data/BootsEmpire/ElectricalServicesData"
import ElectricalworkImageData from "../../data/BootsEmpire/ElectricalworkImageData"
import CleaningServicesData from "../../data/BootsEmpire/CleaningServicesData"
import DeepCleaningServicesImageData from "../../data/BootsEmpire/DeepCleaningServicesImageData"


const DeepCleaningServices = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow:'30px 30px 30px 30px black'}}>Deep Cleaning Services</h1>
                </div>
            </Box>


            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <p className=" fw-bold" style={{ color: '#2764d6' }}>Deep Cleaning Services in Kuwait</p>
                        <h6>Deep Cleaning Services at your Door Step</h6>
                        <p>Having a clean and healthy environment in a commercial and office space makes both customers and employees happy. So why wait any longer, call us for any of your office-related or commercial building cleaning requirements, which will be met by our professional cleaners. We offer one time cleaning as well as scheduled and regular cleaning of your complex. All you have to do is call us, and we can discuss the details.</p>
                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo2} alt="img" />
                    </div>
                </Box>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <p className="pt-4 pb-1 fs-5">Boost Empire General Trading & Contracting as a commercial cleaning company in Kuwait, offers a host range of cleaning services to a wide variety of establishments like shops, offices, schools, warehouses, hospitals, and other commercial buildings. Our experienced and well-trained staffs are equipped to handle all kinds of commercial buildings.</p>
                <p className="pt-4 pb-1 fs-5">Boost Empire General Trading & Contracting’s Office cleaning service in Kuwait is one of the best, and we cater to individual office, entire office building or a corporate building. For cleaning, we use the latest equipment, gadgets, materials and non-toxic, bio-friendly chemicals. This ensures you get the best cleaning without facing any health hazards that often occurs due to the fumes of the cleaning agents.</p>
                <p className="pt-4 pb-1 fs-5">As a commercial cleaning company in Kuwait, with our service, we can help you to maintain a clean working place by ensuring clean floors, kitchenettes, desks and also deep cleaning service.</p>
            </Box>

            <div className="allpackages_div">
                <h2 className=" text-white">Residential & Commercial</h2>
                <h1 className=" pt-0">Wide Range of Cleaning Services</h1>
                <div className="packages_slide">
                    {CleaningServicesData?.map((item, index) => (
                        <div key={item.id} className="packages_life_Slider">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                            <h4>{item.heading}</h4>
                            <p className="px-2 py-3">{item.textInfo}</p>
                        </div>
                    ))}
                </div>
            </div>


            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={DeepCleaningServicesImageData} title={""} />
            </div>

            <Box className='Boost_about_div text-start px-5'>
                <p className="pt-4 pb-1 fs-5">Boost Empire General Trading & Contracting office cleaning service in Kuwait offers the service at a very competitive rate. This means while we ensure the best cleaning service, you do not have to spend a fortune in keeping your office building clean on a regular basis. Many of our clients choose us to clean on every weekday, or twice or thrice a week.</p>
                <p className="pt-4 pb-1 fs-5">As a commercial cleaning company in Kuwait, we tidy your office, take the garbage out, clean the washrooms, kitchen area and tidy the place. When you choose Boost Empire General Trading & Contracting for your office cleaning, you will have the peace of mind because our staffs are not only well trained but vetted too.</p>
                <p className="pt-4 pb-1 fs-5">We are a commercial cleaning company in Kuwait with a personal touch. When you hire us every day after you return to work, you will come to the office that smells fresh and gives you the energy to work.</p>
            </Box>


            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <h6>What's Included?</h6>
                        <p className="pb-4">Cleaning and maintaining of the office and the workspace, this includes cleaning of the shelf, kitchen, pantry, floors and also deep cleaning.</p>
                        <ul>
                            <li>Waste removal, dusting, mopping of the floor.</li>
                            <li>Window cleaning, scraping the dirt, and scratch removal.</li>
                            <li>Fabric, blind, carpet vacuuming and shampooing.</li>
                            <li>Floor polishing.</li>
                        </ul>
                        <p>If you are looking for an office cleaning company in Kuwait that will offer you a wide range of service and that to at a competitive price, call Boost Empire General Trading & Contracting right now. We will give you the satisfaction guaranteed.</p>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo1} alt="img" />
                    </div>
                </Box>
            </Box>


            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Get started with your free estimate</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">Get an Estimate <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default DeepCleaningServices