

const PaintingServicesData = [
    {
        id: 1,
        heading: " Commercial",
        textdetail: "Experience top-notch commercial painting services in the Kuwait, where skilled professionals transform spaces with precision and quality finishes, catering to diverse industries and business needs.",
    },

    {
        id: 2,
        heading: " Residential",
        textdetail: "Revitalize your home's ambiance with exceptional residential painting services in the Kuwait, blending creative color expertise with impeccable craftsmanship for a stunning and personalized living space transformation.",
    },
    {
        id: 3,
        heading: " Villa",
        textdetail: "Transform your villa into a masterpiece of beauty and sophistication with tailored painting solutions that capture your unique aesthetic vision.",
    },
    {
        id: 4,
        heading: " Offices",
        textdetail: "Enhance your office environment with professional painting services in the Kuwait, where dedicated experts harmonize colors and textures to create a productive and inspiring workspace that aligns with your corporate identity",
    },


];
export default PaintingServicesData