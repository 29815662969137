import people1 from '../../assest/BootsEmpire/Electrical Maintenance.jpg'
import people2 from '../../assest/BootsEmpire/1520208937838.jpeg'
import people3 from '../../assest/BootsEmpire/AC Services.jpg'
import people4 from '../../assest/BootsEmpire/Building Maintenance.jpg'
import people5 from '../../assest/BootsEmpire/Construction.jpg'
import people6 from '../../assest/BootsEmpire/Mechanicalwork.png'
import people7 from '../../assest/BootsEmpire/White_Town_Villas_1.jpg'
import people8 from '../../assest/BootsEmpire/waterproofing-membranes.jpg'



const BlogsData = [
    {
        id: 1,
        heading:'Benefits of MEP Contractor in Industry',
        Postedby:"Anirban Bose",
        textdetail:'Mechanical, Electrical and plumbing (MEP) installation of services which provide a comfortable space for the Industrial and commercial buildings, these things are often designed by a specialized MEP Engineering firms',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Benefits of MEP Contractor in Industry',
        Postedby:"Anirban Bose",
        textdetail:'Mechanical, Electrical and plumbing (MEP) installation of services which provide a comfortable space for the Industrial and commercial buildings, these things are often designed by a specialized MEP Engineering firms',
        imgSrc: people2,
    },

    {
        id: 3,
        heading:'Benefits of MEP Contractor in Industry ',
        Postedby:"Anirban Bose",
        textdetail:'Mechanical, Electrical and plumbing (MEP) installation of services which provide a comfortable space for the Industrial and commercial buildings, these things are often designed by a specialized MEP Engineering firms',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'Benefits of MEP Contractor in Industry',
        Postedby:"Anirban Bose",
        textdetail:'Mechanical, Electrical and plumbing (MEP) installation of services which provide a comfortable space for the Industrial and commercial buildings, these things are often designed by a specialized MEP Engineering firms',
        imgSrc: people4,
    },
     {
        id: 5,
        heading:'Benefits of MEP Contractor in Industry ',
        Postedby:"Anirban Bose",
        textdetail:'Mechanical, Electrical and plumbing (MEP) installation of services which provide a comfortable space for the Industrial and commercial buildings, these things are often designed by a specialized MEP Engineering firms',
        imgSrc: people5,
    }, {
        id: 6,
        heading:'Benefits of MEP Contractor in Industry ',
        Postedby:"Anirban Bose",
        textdetail:'Mechanical, Electrical and plumbing (MEP) installation of services which provide a comfortable space for the Industrial and commercial buildings, these things are often designed by a specialized MEP Engineering firms',
        imgSrc: people6,
    }
    , {
        id: 7,
        heading:'Benefits of MEP Contractor in Industry ',
        Postedby:"Anirban Bose",
        textdetail:'Mechanical, Electrical and plumbing (MEP) installation of services which provide a comfortable space for the Industrial and commercial buildings, these things are often designed by a specialized MEP Engineering firms',
        imgSrc: people7,
    }, {
        id: 8,
        heading:'Benefits of MEP Contractor in Industry ',
        Postedby:"Anirban Bose",
        textdetail:'Mechanical, Electrical and plumbing (MEP) installation of services which provide a comfortable space for the Industrial and commercial buildings, these things are often designed by a specialized MEP Engineering firms',
        imgSrc: people8,
    }
   
];
export default BlogsData