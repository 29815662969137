import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import polygon from '../../assest/BootsEmpire/Water Tank Cleaning.webp'
import photo1 from '../../assest/BootsEmpire/photo1.jpg'
import photo2 from '../../assest/BootsEmpire/photo2.jpg'
import photo3 from '../../assest/BootsEmpire/photo3.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import TradingPageImagesData from "../../data/BootsEmpire/TradingPageImagesData"


const TradingPage = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='Aboutus_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Water Tank Cooler</h1>
                </div>
            </Box>

            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <h6>Buy & Get Installed Water Tank Coolers in Kuwait</h6>
                        <p>Water Cooling is the outward flow of heat preventing the overheating because of the environment heat and the water cooling system is the device that is used for cooling and maintaining the water temperature.</p>
                        <p>There is a continuous flow of fresh air into the cooler tank which gradually affects the temperature and circulation of air inside the tank, eventually decreasing the water temperature and settle down the cool water at the bottom of the tank and just opposite to this the hot water will go up. All the hot air and vapours rise to its water level and pass out through the selected outlet of a cooling unit.</p>
                        <Link to={'/contact_us'}> <Button className="morebtn">Request a quote <MdNotifications className=" ms-3 fs-4" /></Button></Link>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo1} alt="img" />
                    </div>
                </Box>
            </Box>

            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why choose our water tank cooler</h1>
                <p className=" text-white mx-5 px-5" style={{ letterSpacing: '1px' }}>In summer’s times, the water tank cooler gives you a sense of relief from the scorching heat. Our water tank cooling system is the most reliable device which provides maintained water temperature not just for domestic use but also for commercial use like plunge pools in spas, water sports, public pools, and many other places, where one can come in contact with water. Everyone can gain the benefit of a water tank cooling system at both commercial and domestic levels in an economical way.</p>
                <p className=" text-white mx-5 px-5" style={{ letterSpacing: '1px' }}>When the intense heat and rising temperature make it difficult to use water for anything, our water cooling system and devices are a perfect fit for summer use. Our wide range of different sizes and capabilities of the water tank cooler makes us one of the best water cooling system providers in Kuwait.</p>

                <div className="row gap-1 Trading_slidediv py-5">
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Reliability</h4>
                        <p className=" text-start">We understand the importance of a consistent and reliable cooling solution. Our water tank coolers are built to last, delivering long-lasting performance without compromising on quality.</p>
                    </div>
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Cutting-Edge Technology</h4>
                        <p className=" text-start">We stay updated with the latest advancements in cooling technology to bring you products that deliver exceptional performance. Our water tank coolers utilize advanced cooling mechanisms.</p>
                    </div>
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Energy Efficiency</h4>
                        <p className=" text-start">Boost Empire General Trading & Contracting Co. W.L.L is committed to sustainability. Our water tank coolers are designed to be energy-efficient, helping you save on energy bills while enjoying a refreshing water supply.</p>
                    </div>
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Tailored Solutions</h4>
                        <p className=" text-start">We recognize that different clients have unique cooling requirements. That’s why we offer a range of water tank cooler options to suit various tank sizes and configurations.</p>
                    </div>
                </div>
            </div>

            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img className="" src={photo2} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <p> The water tank cooler  Kuwait can be installed in various sizes according to the requirement, this can be attached to any sizes of tank cover using stainless steel screws and fasteners. The fan fixed inside the fiberglass cooling house is a heavy-duty type and can be used for 24 hours by manual operation with the help of on and off switch or by an automatic timer. This option will give you a high degree of customization according to your convenience.</p>
                        <p>The water tank cooling device is like a support system for day-to-day working.</p>
                        <p><strong className=" text-primary">Cooler Size:</strong> 19.29 x 19.8 x 22.63 Inches (Approximately)</p>
                        <p><strong className=" text-primary">Fitted With:</strong> High-pressure Exhaust Fan – 1400 RPM & 240 Volts & Filter for Air Circulation.</p>
                        <p><strong className=" text-primary">Weight:</strong> 9.65 Kgs</p>
                        <p><strong className=" text-primary">WARRANTY:</strong> 1 Years Warranty (For Fan). Immediate Replacement.</p>
                    </div>
                </Box>
            </Box>

            <div className=" bg-black">
                <GalleryPhotos GalleryData={TradingPageImagesData} title={""} />

            </div>

            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Complete Water Cooler Tank Trading, Installation & Maintenance Services</h1>

                <div className="row gap-1 Trading_slidediv pt-3 pb-5">
                    <div className=" col-lg-3 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Product Consultation</h4>
                        <p className=" text-start">Unsure about the right water tank cooler for your needs? Our experts are here to guide you through the selection process, considering factors like tank size, cooling capacity, and energy efficiency.</p>
                    </div>
                    <div className=" col-lg-3 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Installation</h4>
                        <p className=" text-start"> Our skilled technicians ensure a seamless installation process, setting up your water tank cooler for optimal performance and efficiency.</p>
                    </div>
                    <div className=" col-lg-3 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Maintenance and Support</h4>
                        <p className=" text-start"> To ensure the longevity of your water tank cooler, we provide regular maintenance services. Our team is always ready to address any issues and provide timely support.</p>
                    </div>

                </div>
            </div>

            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Features of Our Water Tank Coolers</h1>

                <div className="row gap-1 Trading_slidediv pt-3 pb-5">
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Easy Installation</h4>
                    </div>
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Long Lasting</h4>
                    </div>
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Easy Maintenance</h4>
                    </div>

                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 style={{color: '#2764d6'}} className=" text-start">Various Sizes</h4>
                    </div>

                </div>
            </div>

            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-50 text-white">Contact us today for water tank installation service:</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">Get an Estimate <MdChevronRight className=" ms-2 fs-4" /></Button></Link>

            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default TradingPage