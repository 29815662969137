

import people1 from '../../assest/BootsEmpire/sofaCleaningImg2.jpg'
import people2 from '../../assest/BootsEmpire/CarpetCleaningImg2.webp'
import people3 from '../../assest/BootsEmpire/FurnituresCleaningImg2.jpg'



const SofaCleaningServicesData = [
    {
        id: 1,
        heading:'Sofa Cleaning',
        // textInfo:'Lookingompany',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Carpet Cleaning',
        // textInfo:'Weyou',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Furnitures Cleaning',
        // textInfo:'Weoperties',
        imgSrc: people3,
    }
   
];
export default SofaCleaningServicesData