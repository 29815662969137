import comingsoon1 from '../../assest/BootsEmpire/MechanicalWorkImg1.jpg'
import comingsoon2 from '../../assest/BootsEmpire/MechanicalWorkImg2.jpg'
import comingsoon3 from '../../assest/BootsEmpire/MechanicalWorkImg3.jpg'
import comingsoon4 from '../../assest/BootsEmpire/MechanicalWorkImg6.png'






const mechanicalworkImageData = [
    {
        id: 1,
        src: comingsoon3,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon1,
    }, {
        id: 4,
        src: comingsoon4,
    }
   
   
    
];
export default mechanicalworkImageData;