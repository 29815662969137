
import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/AcServicesImg1.jpg'
import photo2 from '../../assest/BootsEmpire/AcServicesImg4.jpeg'
import photo3 from '../../assest/BootsEmpire/ACServicesBanner.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import OurACServicesData from "../../data/BootsEmpire/OurACServicesData"
import ACServicesImageData from "../../data/BootsEmpire/ACServicesImageData"


const ACServices = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>AC Services</h1>
                </div>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <h1>Best a/c service, repair and maintenance in Kuwait?</h1>
                <p className="pt-4 pb-1 fs-5">From routine maintenance to complex installations and emergency repairs, we have the skills and experience needed to deliver outstanding results. Our technicians are highly trained in the latest technologies and industry best practices. We are constantly expanding our knowledge and skills to ensure we are equipped to handle any job, big or small. Whether you need a simple A/C tune-up or a complete electrical overhaul, you can trust our team to provide the highest level of service and support. We understand the importance of reliable, efficient air conditioning and electrical systems, and we are dedicated to providing the best possible service to our clients.</p>
                

                <Box className='Boost_about_div'>
                    <Box className="Boost_about_div_home row">
                        <div className="textdiv col-lg-6 col-12">
                            <p className=" fw-bold" style={{ color: '#2764d6' }}>AC service</p>
                            <h6>Stay Cool and Powered Up: Expert A/C and Electrical Services at Your Doorstep</h6>
                            <p>We are a team of experienced professionals dedicated to providing top-quality AC maintenance, repair, installation and electrical services to our valued customers.</p>
                            <p>We offer a comprehensive range of AC services, including regular maintenance, emergency repairs, and new unit installations.</p>
                            <p>We offer flexible scheduling options, competitive pricing, and a commitment to customer satisfaction that is second to none.</p>
                            <p>Whether you need routine maintenance, urgent repairs, or a brand-new AC installation, you can trust our team to deliver the highest quality service and support. We are dedicated to building long-lasting relationships with our clients, and we look forward to the opportunity to serve you.</p>

                            <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                        </div>
                        <div className="imggdiv col-lg-6 col-12">
                            <img className=" bg-black h-auto" src={photo2} alt="img" />
                            <img className=" bg-black h-auto mt-3" src={photo1} alt="img" />
                        </div>
                    </Box>
                </Box>
            </Box>

            
            <div className="allpackages_div">
                <h1>What We Do</h1>
                <h1 className=" fs-4">We Provide The Best a/c & electrical services to Residential and Commercial clients.</h1>
                <div className="packages_slide">
                    {OurACServicesData?.map((item, index) => (
                        <div key={item.id} className="packages_life_Slider">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                            <h4>{item.heading}</h4>
                            <p className="px-2 py-3">{item.textInfo}</p>
                        </div>
                    ))}
                </div >
            </div >



            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={ACServicesImageData} title={""} />
            </div>


            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">BOOK AN APPOINTMENT TODAY</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">BOOK NOW<MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default ACServices