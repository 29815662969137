
import people1 from '../../assest/BootsEmpire/AcServicesImg7.jpg'
import people2 from '../../assest/BootsEmpire/AcServicesImg6.webp'
import people3 from '../../assest/BootsEmpire/AcServicesImg5.png'
import people4 from '../../assest/BootsEmpire/AcServicesImg4.jpeg'
import people5 from '../../assest/BootsEmpire/AcServicesImg3.jpg'
import people6 from '../../assest/BootsEmpire/AcServicesImg2.jpg'


const OurACServicesData = [
    {
        id: 1,
        heading:'AC Repair',
        textInfo:'We repair all branded AC in all over Kuwait. Our Ac Repair service is best AC service in Kuwait. We have 10 years experience in this filed. If you face any problem with your ac then you can just call us +96550286435. We will be here at a time.',
        imgSrc: people1,
    },
    
    {
        id: 2,
        heading:'AC Installation',
        textInfo:'Looking for professional AC installation services in Kuwait? Look no further! Our experienced technicians are here to provide efficient and reliable AC installation solutions tailored to your needs. Contact us today at +96550286435 for a hassle-free AC installation.',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Ac Maintenance',
        textInfo:'Looking for reliable AC maintenance services in Kuwait? Look no further! We provide top-notch AC maintenance solutions to keep your cooling systems running efficiently. Contact us at +96550286435 for all your Ac Maintenance needs.',
        imgSrc: people3,
    },
    {
        id: 4,
        heading:'AC Cleaning',
        textInfo:'Breathe Easy with Our Professional Ac Cleaning Services. Our team uses the latest tools and techniques to ensure your AC unit is thoroughly cleaned. Whether it is commercial AC or residential. Please Contact us.',
        imgSrc: people4,
    },
    
    {
        id: 5,
        heading:'AC Shifting',
        textInfo:'Looking to shift your air conditioning unit? Look no further we are here. Our experienced team will ensure a hassle-free and efficient process, leaving you with a perfectly relocated unit and a comfortable home or office..',
        imgSrc: people5,
    },
    {
        id: 6,
        heading:'AC Not Cooling',
        textInfo:'Is your AC not cooling properly? Don’t suffer in the heat – call us now at +96550286435 for fast and reliable AC repair services. Our expert technicians will quickly diagnose and fix the problem. Contact us today for all your Ac service needs..',
        imgSrc: people6,
    }
   
];
export default OurACServicesData