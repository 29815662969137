import people1 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-015.jpg'
import people2 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-000.jpg'
import people3 from '../../assest/BootsEmpire/modern-home-exterior.jpg'


const OurDivissionData = [
    {
        id: 1,
        heading:'BUILDING',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'INDUSTRIAL',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'HOME & VILLA',
        imgSrc: people3,
    }
   
];
export default OurDivissionData