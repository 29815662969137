
import people1 from '../../assest/BootsEmpire/calendar.png'
import people2 from '../../assest/BootsEmpire/cube.png'
import people3 from '../../assest/BootsEmpire/office-building.png'
import people4 from '../../assest/BootsEmpire/ruler.png'
import people5 from '../../assest/BootsEmpire/3d-house.png'
import people6 from '../../assest/BootsEmpire/suitcase.png'




const EverySpaceCountData = [
    {
        id: 1,
        heading: "Building Planning",
        textdetail: "Our Interior Designers will help you through the process of building design and planning. We can complete any scope of work, from renovating or expanding a small house to designing a large estate home.",
        // linktag:"Seehot",
        imgSrc: people1,
    },

    {
        id: 2,
        heading: "Renovating Space",
        textdetail: "We renovate any spaces large or small, including kitchens and bathrooms, as well as homes and offices.",
        // linktag:"Checkomains",
        imgSrc: people2,
    },
    {
        id: 3,
        heading: "Commercial Space",
        textdetail: "We design commercial space for businesses and organizations of all types, from small businesses to large corporations and government agencies.",
        // linktag:'Browsemains',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: "Real Estates",
        textdetail: "Professional interior design services for your real estate property. We can create a beautiful new look for your real estate business office, and help you with all the details.",
        // linktag:'Browsemains',
        imgSrc: people4,
    },
    {
        id: 5,
        heading: "Small Offices",
        textdetail: "We are a small office interior design company that specializes in creating an environment that is conducive to collaboration and productivity.",
        // linktag:'Browsemains',
        imgSrc: people5,
    },
    {
        id: 6,
        heading: "Home Interiors",
        textdetail: "A home full of lights, beautiful furniture and accessories make it a dream house. Our designers will help you get an amazing home with the best quality and reasonable prices.",
        // linktag:'Browsemains',
        imgSrc: people6,
    }
];
export default EverySpaceCountData