import comingsoon1 from '../../assest/BootsEmpire/PostConstructionCleaningImg1.jpg'
import comingsoon2 from '../../assest/BootsEmpire/PostConstructionCleaningImg2.jpg'
import comingsoon3 from '../../assest/BootsEmpire/PostConstructionCleaningImg3.jpg'
import comingsoon4 from '../../assest/BootsEmpire/PostConstructionCleaningImg4.png'
import comingsoon5 from '../../assest/BootsEmpire/PostConstructionCleaningImg5.jpg'






const PostConstructionCleaningImageData = [
    {
        id: 1,
        src: comingsoon4,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon3,
    }, {
        id: 4,
        src: comingsoon5,
    }
    , {
        id: 5,
        src: comingsoon1,
    }
    
];
export default PostConstructionCleaningImageData;