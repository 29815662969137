
import client1 from '../../assest/BootsEmpire/shapoorjipallonji.png'
import client2 from '../../assest/BootsEmpire/gulf spic logo.jpeg'
import client3 from '../../assest/BootsEmpire/mabaneelogo.png'
import client4 from '../../assest/BootsEmpire/tamdeengroup.svg'
import client5 from '../../assest/BootsEmpire/al-sagar-group-kuwait-Ansaldo-Spa.jpg'
import client6 from '../../assest/BootsEmpire/SLB_Logo_2022.svg.png'




const OurClientData = [
    {
        id: 1,
        imgSrc: client1,
        // name: 'Chennai client'
    },
    {
        id: 2,
        imgSrc: client2,
        // name: 'Delhi client'
    },
    {
        id: 3,
        imgSrc: client3,
        // name: 'Kochi client'

    }, {
        id: 4,
        imgSrc: client4,
        // name: 'NHAI Projects'
    },
    {
        id: 5,
        imgSrc: client5,
        // name: 'Kochi client'

    }, {
        id: 6,
        imgSrc: client6,
        // name: 'NHAI Projects'
    }
];
export default OurClientData

