
import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/MechanicalWorkImg3.jpg'
import photo3 from '../../assest/BootsEmpire/MechanicalWorkBanner.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import ElectroMechanicalEngineeringData from "../../data/BootsEmpire/ElectroMechanicalEngineeringData"
import MechanicalServicesData from "../../data/BootsEmpire/MechanicalServicesData"
import mechanicalworkImageData from "../../data/BootsEmpire/mechanicalworkImageData"


const MechanicalWorks = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow:'30px 30px 30px 30px black'}}>Mechanical Works</h1>
                </div>
            </Box>


            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <p className=" fw-bold" style={{ color: '#2764d6' }}>mechanical works</p>
                        <h6>Mechanical Engineering Services in Kuwait</h6>
                        <p>We have been providing mechanical services and  committed to delivering quality solutions in areas such as HVAC, plumbing, fire protection systems, and other mechanical installations.</p>
                        <p>With our expertise, advanced technology, and commitment to excellence, we are your trusted partner for all your mechanical needs.</p>
                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo1} alt="img" />
                    </div>
                </Box>

            </Box>


            <div className="allpackages_div">
                <h1>Electro Mechanical Engineering Services Company, a contractor based in Kuwait.</h1>
                <div className="packages_slide">
                    {ElectroMechanicalEngineeringData?.map((item, index) => (
                        <div key={item.id} className="packages_life_Slider">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                            <h4>{item.heading}</h4>
                            <p className="px-2 py-3">{item.textInfo}</p>
                        </div>
                    ))}
                </div >
            </div >


            <div className="SpaceCount_div">
                <h1 className=" fw-bold py-3">Mechanical Services</h1>
                <div className="SpaceCount_slidediv">
                    {MechanicalServicesData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="SpaceCount_div_slide">
                                {/* <img src={item.imgSrc} alt={`img-${item.id}`} /> */}
                                <h4>{item.heading}</h4>
                                <p>{item.textdetail}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

              <Box className='Boost_about_div text-start px-5'>
                <p className="pt-4 pb-1 fs-5">Kuwait’s thriving economy and ambitious development projects make it an ideal location for our mechanical works services. With a focus on sustainability and energy efficiency, the demand for reliable mechanical systems and services is growing rapidly. As a leading provider in Kuwait, we are well-positioned to deliver innovative and compliant solutions that meet the unique needs of this dynamic market.</p>
                <p className="pt-4 pb-1 fs-5">When it comes to mechanical works services in Kuwait, Boost Empire General Trading & Contracting Co. W.L.L is your go-to partner. Contact us today to discuss your mechanical requirements and discover how our expertise and services can benefit your project. Let us be your trusted provider of high-quality mechanical solutions, ensuring the efficient operation and performance of your systems.</p>
            </Box>

            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={mechanicalworkImageData} title={""} />
            </div>

            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Get started with your free estimate</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">Get an Estimate <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default MechanicalWorks