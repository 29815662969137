
import people1 from '../../assest/BootsEmpire/building-contractor.png'
import people2 from '../../assest/BootsEmpire/plumbing-service.webp'
import people3 from '../../assest/BootsEmpire/Mechanicalwork.png'
import people4 from '../../assest/BootsEmpire/Building Maintenance.jpg'
import people5 from '../../assest/BootsEmpire/Electrical Maintenance.jpg'
import people6 from '../../assest/BootsEmpire/AC Services.jpg'




const TradingAndContactingServicesData = [
    {
        id: 1,
        heading:'Contracting Work',
        textdetail:'Contractor Service in Kuwait We at Boost Empire General Trading & Contracting Company W.L.L, provides Trading',
        linkTag: 'contracting_work',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Plumbing Services',
        textdetail:'Looking for a reliable Plumber in Kuwait? No matter what type of plumbing services you',
        linkTag: 'plumbing_services',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Mechanical Works',
        textdetail:'mechanical works Mechanical Engineering Services in Kuwait We have been providing mechanical services and  committed',
        linkTag: 'mechanical_works',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'Building Maintenance',
        textdetail:'Let us maintain your building Discover Excellence in Building Maintenance Services Our services encompass various',
        linkTag: 'building_maintenance',
        imgSrc: people4,
    },
     {
        id: 5,
        heading:'Electrical Maintenance ',
        textdetail:'Electrical Maintenance Kuwait As an electrical maintenance services provider, our primary focus would be to',
        linkTag: 'electrical_maintenance',
        imgSrc: people5,
    },
     {
        id: 6,
        heading:'AC Services ',
        textdetail:'Best a/c service, repair and maintenance in Kuwait From routine maintenance to complex installations and',
        linkTag: 'AC_services',
        imgSrc: people6,
    }
   
   
];
export default TradingAndContactingServicesData