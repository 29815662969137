import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/PlumbingServicesImg1.jpg'
import photo2 from '../../assest/BootsEmpire/PlumbingServicesImg6.jpeg'
import photo3 from '../../assest/BootsEmpire/PlumbingServicesBanner.jpeg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import PlumbingServicesData from "../../data/BootsEmpire/PlumbingServicesData"
import PlumbingworkImageData from "../../data/BootsEmpire/PlumbingworkImageData"


const PlumbingServices = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Plumbing Services</h1>
                </div>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <h1>Looking for a reliable Plumber in Kuwait?</h1>
                <p className="pt-4 pb-1 fs-5">No matter what type of plumbing services you need, Boost Empire General Trading & Contracting can provide repairs, installation, and service.</p>
                

                <Box className='Boost_about_div'>
                    <Box className="Boost_about_div_home row">
                        <div className="textdiv col-lg-6 col-12">
                            <p className=" fw-bold" style={{ color: '#2764d6' }}>About Us</p>
                            <h6>We Are A Group Of Professional Plumbers</h6>
                            <p>While you may be qualified to fix a leaking pipe under the sink or unclog the shower drain, complex plumbing system are best left to the professionals. Low drain and water leakage can damage your property. Thereby, always avail the best plumbing service for installing pipes.</p>
                            <p>With a team of best plumbers in the town, we respond immediately to your requests, point the issue and provide efficient services at very reasonable prices. Our goal is to provide quality services to customers.</p>
                            <p>Our experts are available 24/7 and can reach your place to deal with any problem on a single phone call.</p>

                            <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                        </div>
                        <div className="imggdiv col-lg-6 col-12">
                            <img className=" bg-black h-auto" src={photo2} alt="img" />
                            <img className=" bg-black h-auto mt-3" src={photo1} alt="img" />
                        </div>
                    </Box>
                </Box>
            </Box>


            <div className="SpaceCount_div">
                <h1 className=" fw-bold py-3">Plumbing Services</h1>
                <h1 className=" fw-bold pb-3 fs-4">Available for 24/7 Emergency Service</h1>
                <div className="SpaceCount_slidediv">
                    {PlumbingServicesData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="SpaceCount_div_slide">
                                {/* <img src={item.imgSrc} alt={`img-${item.id}`} /> */}
                                <h4>{item.heading}</h4>
                                <p>{item.textdetail}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>


            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={PlumbingworkImageData} title={""} />
            </div>

            <Box className='Boost_about_div text-start px-5'>
                <p className="pt-4 pb-1 fs-5">Quick Service On Emergency Call - 24/7, No matter what type of plumbing services you need, Boost Empire General Trading & Contracting can provide repairs, installation, and service.</p>
            </Box>

            



            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Contact for Plumbing Services</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US<MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default PlumbingServices