
import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-009.jpg'
import photo3 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-085.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import EverySpaceCountData from "../../data/BootsEmpire/EverySpaceCountData"
import CommercialResidentialInteriorImagesData from "../../data/BootsEmpire/CommercialResidentialInteriorImagesData"


const CommercialResidentialInteriors = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center">Interiors</h1>
                </div>
            </Box>

            <Box className='Boost_about_div'>
                <h1>Looking for Quality Interior Company in Kuwait?</h1>
                <p className=" mx-5 px-5 pt-4 pb-5 fs-5">We specialize in providing top-notch interior works that redefine spaces with elegance, precision, and exceptional craftsmanship. Whether you’re looking to create a luxurious residential retreat or a functional and inspiring commercial environment, we have the expertise to exceed your expectations.</p>

                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <p className=" fw-bold" style={{ color: '#2764d6' }}>quality interior works</p>
                        <h6>Interior Design Firm Based Kuwait</h6>
                        <p>A descriptive paragraph that tells clients how good you are and proves that you are the best choice that they’ve made. This paragraph is also for those who are looking out for a reliable interior design firm.</p>
                        <p>You can use a few enticing words and flaunt your capabilities that will attract future clients and encourage them to hire you right away.</p>
                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo1} alt="img" />
                    </div>
                </Box>
            </Box>


            <div className="SpaceCount_div">
                <p className=" text-white fw-bold mb-0">Every Space Count</p>
                <h1 className=" fw-bold py-3">WIDE RANGE OF SPACES COVERS</h1>
                <div className="SpaceCount_slidediv">
                    {EverySpaceCountData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="SpaceCount_div_slide">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                                <p>{item.textdetail}</p>
                            </div>
                        </Link>

                    ))}
                </div>
            </div>
           
            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={CommercialResidentialInteriorImagesData} title={""} />

            </div>

            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Ready to Innovate? <br />
                    <span className=" fs-5">Whether your query is about design ideas, materials, pricing, or anything else, our team is ready to answer all your questions.</span>
                </h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">Call to Action <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default CommercialResidentialInteriors