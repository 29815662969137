
import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo2 from '../../assest/BootsEmpire/WaterproofingsolutionsImg1.jpg'
import photo3 from '../../assest/BootsEmpire/WaterproofingsolutionsBanner.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import BuildingMaintenanceWorkImageData from "../../data/BootsEmpire/BuildingMaintenanceWorkImageData"
import WaterproofingSolutionsData from "../../data/BootsEmpire/WaterproofingSolutionsData"
import WaterproofingSolutionsImageData from "../../data/BootsEmpire/WaterproofingSolutionsImageData"


const WaterproofingSolutions = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Waterproofing Solutions</h1>
                </div>
            </Box>



            <Box className='Boost_about_div text-start px-5'>
                <h1>We Provide Waterproofing solutions in Kuwait</h1>
                <p className=" fs-5"> We offer comprehensive services to protect your home and businesses from leaks, floods and water damage. Our team of experienced technicians has over 10 years’ knowledge and experience in the field, working with both commercial and residential properties. They will ensure that you receive the best service possible.</p>

                <Box className='Boost_about_div'>
                    <Box className="Boost_about_div_home row">
                        <div className="imggdiv col-lg-6 col-12">
                            <img className=" bg-black h-auto" src={photo2} alt="img" />
                        </div>
                        <div className="textdiv col-lg-6 col-12">
                            <h6>Commited to Fix Water Leakage</h6>
                            <p>We understand the importance of protecting your property from water damage. Whether it’s a residential, commercial, or industrial building, our expert team is here to provide top-quality waterproofing solutions tailored to your specific needs. With our commitment to excellence and attention to detail, we ensure that your property remains dry, safe, and structurally sound.</p>

                            <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                        </div>

                    </Box>
                </Box>
            </Box>


            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>
                <p className=" mx-5 px-5 mb-0 text-white" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>

                <div className="SpaceCount_slidediv mt-5">
                    {WaterproofingSolutionsData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="SpaceCount_div_slide">
                                {/* <img src={item.imgSrc} alt={`img-${item.id}`} /> */}
                                <h4>{item.heading}</h4>
                                <p>{item.textdetail}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={WaterproofingSolutionsImageData} title={""} />
            </div>

            <Box className='Boost_about_div text-start px-5'>
                <p className="pb-1 fs-5">Homeowners take great pains to keep their houses intact, from the issues like water damage which can cause living areas to become unusable. Opting for the best waterproofing services is necessary to prevent roofs from collapsing, which could result in terrible payback for putting so much hard work into caring for one’s home. India is a tropical region that often sees severe rainfall every year. If proper waterproofing services are not performed during or shortly after the construction of any building project, we may see our investment end up as a total loss with no hope of repair.</p>
                <p className="pb-1 fs-5">Applying for the best waterproofing services is an essential phenomenon for any building project. It ensures a structure is 100% resistant to water infiltration thereby preventing irreversible and expensive damage. At the same time, awaterproofing solution also allows any water to drain away safely. Waterproofing solutions can be used to accomplish multi-purpose needs.</p>
                <p className="pb-1 fs-5">As suggested by waterproofing experts, they can effectively evade dampness, efflorescence, algal/fungal growth, and leakages from sheds, tanks, underground areas, etc. This can be done by applying construction chemicals like nanosil, tigersil during or after construction. Nevertheless, it is also applicable while repairing the area. </p>
            </Box>


            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Contact us for Commercial or Residential Waterproofing works</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY<MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default WaterproofingSolutions