import people1 from '../../assest/BootsEmpire/GympsumBoardimg.jpg'
import people2 from '../../assest/BootsEmpire/FloorTilingImg.jpg'
import people3 from '../../assest/BootsEmpire/WallTilingImg.webp'



const GypsumFloorTilesWorkData = [
    {
        id: 1,
        heading:'Gympsum Board',
        textInfo:'We provide Gympsum work in Kuwait We are providing gympsum work in different sectors of construction.',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Floor Tiling',
        textInfo:'Our mobile tiling team has the experience, knowledge, qualifications and equipment to complete any job from start to finish.',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Wall Tiling',
        textInfo:'We specialize in the installation of stone, porcelain and marble tile in both residential and commercial buildings.',
        imgSrc: people3,
    }
   
];
export default GypsumFloorTilesWorkData