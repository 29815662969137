import polygon from '../../assest/BootsEmpire/about-us-page-banner.jpg'
import { Box } from "@mui/material"
import '../../styles/BootsEmpire/AboutUsStyle.css'
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import BootsEmpireFooter from '../../common/BootsEmpire/Footer/BootsEmpireFooter'
import EnterpriseImg from '../../assest/BootsEmpire/slider-02-1.jpg'



const PrivacyPolicy = () => {

    return (
        <>
            <BootsEmpireHeader />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Privacy Policy</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle about_textdiv">
                <div className="">
                    <p className=" fw-bold">Privacy Policy</p>
                    <p>A privacy policy states how you will respect the privacy of your website users. It says what information you will gather, how you will use it and how you will keep it secure.</p>
                    <p>A privacy policy is a legal document that discloses some or all of the ways a party gathers, uses, discloses and manages a customer's data. The exact contents of a privacy policy will depend upon the applicable law and may need to address the requirements of multiple countries or jurisdictions. While there is no universal guidance for the content of specific privacy policies, a number of organizations provide example forms.</p>
                    <p>Boost Empire General Trading & Contracting  is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.</p>
                    <p>Boost Empire General Trading & Contracting may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 01st January 2017.</p>
                    <p className=" fw-bold">What We Collect</p>
                    <p>We may collect the following information:</p>
                    <p>name and job title</p>
                    <p>contact information including email address</p>
                    <p>demographic information such as postcode, preferences and interests</p>
                    <p>other information relevant to customer surveys and/or offers</p>
                    <p className=" fw-bold">What we do with the information we gather</p>
                    <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
                    <p>Internal record keeping.</p>
                    <p>We may use the information to improve our products and services.</p>
                    <p>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</p>
                    <p>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</p>

                    <p className=" fw-bold">Security</p>
                    <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
                    <p className=" fw-bold">Controlling your personal information</p>
                    <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
                    <p>whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</p>
                    <p>if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at abaris@info.in</p>
                    <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
                    <p className=" fw-bold">Disclaimer policy</p>
                    <p>A disclaimer may specify mutually-agreed and privately-arranged terms and conditions as part of a contract; or may specify warnings or expectations to the general public (or some other class of persons) in order to fulfill a duty of care owed to prevent unreasonable risk of harm or injury. Some disclaimers are intended to limit exposure to damages after harm or injury has already been suffered. Additionally, some kinds of disclaimers may represent a voluntary waiver of a right or obligation that may be owed to the disclaiming.</p>
                    <p>Disclaimers vary in terms of their uniformity. Some may vary depending on the specific context and parties involved, while other types of disclaimers may strictly adhere to a uniform and established set of formalities that are rarely or never modified, except under official authority.</p>

                    <p className=" fw-bold">Website disclaimer covers the following:</p>
                    <p>No warranties</p>
                    <p>Limitations of liability</p>
                    <p>Exceptions</p>
                    <p>Reasonableness</p>
                    <p>Other parties</p>
                    <p>Unenforceable provisions</p>
                    <p>This website disclaimer</p>

                    <p className=" fw-bold">Website disclaimer</p>
                    <p>The information contained in this website is for general information purposes only. The information is provided by [business name] and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
                    <p>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arise out of, or in connection with, the use of this website.</p>
                    <p>Through this website you are able to link to other websites which are not under the control of [business name]. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
                    <p>Every effort is made to keep the website up and running smoothly. However, [business name] takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>

                    <p className=" fw-bold">Cancellation & Refund Policy</p>
                    <p>Cancellation and Refund policy depends on the hotels and resorts where the reservation has been made , and it varies from hotel to a hotel and factors such as the seasonality , duration between the date of cancellation and the check in date , etc  . Boost Empire General Trading & Contracting will charge a processing fee of 10% in case of cancellations / amendments .</p>

                </div>
            </div>


            <BootsEmpireFooter />

        </>
    )
}

export default PrivacyPolicy