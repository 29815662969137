

import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/ContractingWorkImg3.webp'
import photo3 from '../../assest/BootsEmpire/ContractingWorkbanner.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import ContractingWorkImgeData from "../../data/BootsEmpire/ContractingWorkImgeData"
import ContractingWorkServicesData from "../../data/BootsEmpire/ContractingWorkServicesData"


const ContractingWork = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center">Contracting Work</h1>
                </div>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <h1>CONTRACTOR SERVICE IN KUWAIT</h1>
                <p className="pt-4 pb-1 fs-5">We at Boost Empire General Trading & Contracting Co. W.L.L, provides Trading & MEP Contracting Services in Kuwait. We are pleased to offer our customers professional and personal service in various industries such as building steel structures for residential/office complexes, commercial buildings and industrial installations (such as power plants, water treatment plants and oil platform.</p>
                <p className="pt-3 pb-1 fs-5">We develop maintenance plans determining the appropriate mix of reactive, planned, and condition based maintenance models for facilities lifecycle, maximisation.</p>
                <p className="pt-3 pb-1 fs-5">Boost Empire General Trading & Contracting is currently having annual contracts for MEP services and General maintenance including pest control and multi termite treatment.</p>
                <p className=" fw-bold fs-3">Our services cover the following systems and facilities as per the requirement fo the customer.</p>
                <ul>
                    <li>General Maintenance</li>
                    <li>Building Maintenance</li>
                    <li>Water proofing of roof and water storage tank.</li>
                    <li>Floor and Wall tiling works.</li>
                    <li>Carpentry and Flooring Contracting</li>
                    <li>Partitions and False Ceilings Contracting.</li>
                    <li>Interior Decoration, Painting and complete renovation of villas, offices and shops.</li>
                    <li>Electrical Maintenance.</li>
                    <li>Plumbing and Sanitary Contracting.</li>
                    <li>Building Cleaning Services, Water tank/reservoir & Frontage Glass cleaning.</li>
                    <li>Gate barriers and sliding doors contracting.</li>
                    <li>Electromechanical equipment’s installation and maintenance.</li>
                    <li>General civil works including aluminium and glass work</li>
                    <li>New construction HVAC installation</li>
                    <li>Existing structure HVAC installation (i.e. replacement)</li>
                    <li>HVAC maintenance and repairs.</li>
                    <li>Refrigeration system installation, maintenance and repairs.</li>
                    <li>Air conditioning system installation.</li>
                    <li>Air vents installation.</li>
                    <li>Refrigeration system (e.g. commercial, industrial or scientific installation)</li>
                    <li>Heating, ventilation and air conditioning (HVAC) contractors.</li>
                    <li>Duct work (e.g. cooling, dust collection, exhaust, heating and ventilation installation).</li>
                    <li>Ventilation equipment installation.</li>
                    <li>Heating and ventilation system component (e.g. air registers, diffusers and filters installation).</li>
                </ul>

              
                <Box className="Boost_about_div_home row  pt-5">
                    <div className="textdiv col-lg-6 col-12">
                        <h6>CONTRACTOR SERVICE IN KUWAIT</h6>
                        <p>Boost Empire General Trading & Contracting in Kuwait encompass a comprehensive range of maintenance and construction solutions to cater to diverse needs. General Maintenance services offer a broad spectrum of upkeep tasks for various structures. Building Maintenance focuses on ensuring the sustained functionality and aesthetics of buildings. Waterproofing services target roofs and water storage tanks, safeguarding against water damage.</p>
                        <p>Floor and wall tiling services contribute to aesthetic enhancement and durability. Carpentry and flooring contracting provide solutions for woodwork and flooring needs. Partitions and False Ceilings Contracting create functional spaces with modern design. Interior Decoration and Painting services facilitate complete transformations of villas, offices, and shops.</p>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo1} alt="img" />
                    </div>
                </Box>

                <p className="pt-3 pb-1 fs-5">Electrical Maintenance guarantees the safety and efficiency of electrical systems. Plumbing and Sanitary Contracting addresses water-related installations. Building Cleaning Services include cleaning water tanks and glass frontages, maintaining a pristine appearance. Gate barriers and sliding doors contracting ensure secure and convenient access.</p>
                <p className="pt-3 pb-1 fs-5">Electromechanical equipment installation and maintenance contribute to operational efficiency. General civil works, including aluminum and glass work, form the basis of construction. HVAC services cover new installations, replacements, maintenance, and repairs. Refrigeration system services encompass installation and maintenance.</p>
                <p className="pt-3 pb-1 fs-5">Ventilation and air conditioning solutions create comfortable environments. HVAC contractors cover diverse aspects, including ductwork and component installations. This range of services collectively shapes Kuwait’s infrastructural landscape, meeting both functional and aesthetic requirements.</p>

            </Box>


            <div className="SpaceCount_div">
                <h1 className=" fw-bold py-3">OUR SERVICES</h1>
                <div className="SpaceCount_slidediv">
                    {ContractingWorkServicesData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="SpaceCount_div_slide">
                                {/* <img src={item.imgSrc} alt={`img-${item.id}`} /> */}
                                <h4>{item.heading}</h4>
                                <p>{item.textdetail}</p>
                            </div>
                        </Link>

                    ))}
                </div>
            </div>

            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={ContractingWorkImgeData} title={""} />
            </div>

            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Get started with your free estimate</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">Get an Estimate <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default ContractingWork