
import people6 from '../../assest/BootsEmpire/Simple-Modern.jpg'
import people7 from '../../assest/BootsEmpire/kuwait-banner.webp'
import people8 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-041.jpg'



const TopBannersData = [
    {
        id: 1,
        src: people7,
    },
    {
        id: 2,
        src: people8,
    },
    {
        id: 3,
        src: people6,
    }, 
   
];
export default TopBannersData;
