
import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/PostConstructionCleaningBannerImg.jpg'
import photo2 from '../../assest/BootsEmpire/PostConstructionCleaningImg1.jpg'
import photo3 from '../../assest/BootsEmpire/PostConstructionCleaningImg5.jpg'
import photo4 from '../../assest/BootsEmpire/PostConstructionCleaningImg4.png'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import PostConstructionCleaningImageData from "../../data/BootsEmpire/PostConstructionCleaningImageData"




const PostConstructionCleaning = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo1} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Post Construction Cleaning</h1>
                </div>
            </Box>

            <Box className='Boost_about_div text-start'>
                <h1 className=" fw-bold px-5 py-4 fs-2"> COMMERCIAL & RESIDENTIAL POST CONSTRUCTION CLENAING</h1>

                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo2} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <p className="pb-1 fs-5">After the construction of a villa or commercial building is complete like a restaurant, office, showroom, or even a click or hospital, detailed and thorough cleaning of the property is of paramount importance. It is essential that the debris and the dust from the construction site are removed. Both construction workers and building contractors can depend on us to do the deep cleaning of the post-construction site. Boost Empire General Trading & Contracting is one of the best post-construction cleaning services in Kuwait. Our well-trained and experienced cleaning staffs are equipped with the best cleaning materials, equipment and chemicals to remove the dust, debris and dirt from the post-construction site.</p>
                       
                    </div>
                </Box>
            </Box>

            


            <Box className='Boost_about_div text-start px-5'>
                <h1>Why We Offer</h1>
                <p className="pb-1 fs-5">Being the best post-construction cleaning services in Kuwait, we offer a detailed cleaning of the following:</p>
                <ul className=" pt-3 fs-5">
                    <li>The dusting of the hidden areas</li>
                    <li>Removing the dirt settles in the corners and nooks of the building</li>
                    <li>The dusting of the crevices of hinges</li>
                    <li>Removing the grouting material residues that are often looked over on the tiles</li>
                    <li>Removing any gum and masking tape marks on the walls, window and door panels and the floors</li>
                    <li>Removal of any paint marks on the doors, windows, walls, sanitaryware and kitchen equipment and others.</li>
                    <li>We sanitize the bathrooms, kitchens and the pantry</li>
                </ul>
                <p className="pb-1 fs-5">So when you are hiring a post-construction cleaning company, be sure they offer you all these services.</p>
            </Box>

            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <h1>Commercial</h1>
                        <p className="pb-1 fs-5">Ensure your project shines from the start with our post-construction cleaning services. We tackle dust, debris, and grime, leaving every surface polished. Elevate your space's appeal and safety. Choose professional, choose us for cleaning anywhere in Kuwait.</p>
                        
                        <h1 className="mt-5">Residential</h1>
                        <p className="pb-1 fs-5">Transform your home after the chaos of construction with our residential post-construction cleaning. We remove leftover debris, sanitize every corner, and make your space move-in ready. Enjoy the beauty of your new space without the mess.</p>

                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>

                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo3} alt="img" />
                        <img className=" bg-black h-auto mt-4" src={photo4} alt="img" />
                    </div>
                </Box>
            </Box>


            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us?</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>

            <div className=" bg-black">
                <GalleryPhotos GalleryData={PostConstructionCleaningImageData} title={""} />
            </div>


            <Box className='Boost_about_div text-start px-5'>
                <p className="pb-1 fs-5">There are many post-construction cleaning companies in Kuwait, but Boost Empire General Trading & Contracting as a provider of post-construction cleaning service in Kuwait, is different than others because of the service that we provide. Most companies use vacuum cleaners, buckets and regular mops and often outdated equipment to clean the site. Also, the cleaning of a post-construction site requires the usage of a variety of hard chemicals. It is for you to decide whether you want a company that uses such outdated and hard, toxic chemicals to do the job or a company that uses the latest cleaning equipment, bio-friendly, non-toxic chemicals. Despite using non-toxic chemicals Boost Empire General Trading & Contracting does not compromise on cleaning the site, we always leave the site in a state that you can easily get ‘Certificate of Occupancy’.</p>
                <p className="pb-1 fs-5">Boost Empire General Trading & Contracting as a post-construction cleaning service in Kuwait also does not use unskilled, non-vetted labours for the job. So if you are looking to hire a company that will do the post-construction cleaning of your site, contact us now. Rest, we will do it for you.</p>
            </Box>


            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Get started with your free estimate</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default PostConstructionCleaning