

const ElectricalServicesData = [
    {
        id: 1,
        heading: "Skilled and Experienced Technicians",
        textdetail: "Our team of electrical maintenance experts consists of highly skilled and experienced electrical technicians who possess in-depth knowledge of electrical systems and maintenance requirements. They undergo rigorous training to stay up-to-date with the latest industry practices and advancements. Rest assured, your electrical maintenance needs will be handled by competent professionals.",
    },

    {
        id: 2,
        heading: "Comprehensive Electrical Maintenance Services",
        textdetail: "We offer a wide range of electrical maintenance services in Kuwait to ensure the smooth functioning of your property. From routine inspections, testing, and preventive maintenance to troubleshooting, repairs, and upgrades, we have the expertise to handle various electrical systems, including lighting, power distribution, control panels, wiring, and more.",
    },
    {
        id: 3,
        heading: "Safety and Compliance",
        textdetail: "Electrical safety is our utmost priority. We strictly adhere to industry safety standards and regulations to ensure the protection of your property, occupants, and assets. Our technicians follow proper protocols, utilize appropriate personal protective equipment, and employ safe practices during all maintenance activities.",
    },
    {
        id: 4,
        heading: "Customized Maintenance Plans",
        textdetail: "We recognize that each property has unique electrical maintenance requirements. Our team works closely with you to assess your needs, understand your goals, and design customized maintenance plans that align with your budget and priorities. We aim to optimize the performance and longevity of your electrical systems while minimizing downtime and disruptions.",
    },
    {
        id: 5,
        heading: "Timely and Efficient Service:",
        textdetail: "We understand the importance of prompt and efficient electrical maintenance services. Our technicians are punctual, equipped with the necessary tools and equipment, and ready to address your maintenance needs efficiently. We strive to complete projects within the agreed-upon timeframe, ensuring minimal disruption to your daily operations by our electrical enginners.",
    },
    {
        id: 6,
        heading: "24X7 Emergency Support",
        textdetail: "Electrical emergencies can happen unexpectedly, jeopardizing the safety and functionality of your property. That's why we offer 24/7 emergency support to address any urgent electrical issues. Our dedicated team is just a phone call away, ready to respond promptly and effectively to resolve any electrical maintenance requirments in emergency.",
    },

];
export default ElectricalServicesData