

import comingsoon1 from '../../assest/BootsEmpire/cooling1.jpg'
import comingsoon2 from '../../assest/BootsEmpire/cooling2.jpg'
import comingsoon3 from '../../assest/BootsEmpire/photo1.jpg'
import comingsoon4 from '../../assest/BootsEmpire/photo2.jpg'
import comingsoon5 from '../../assest/BootsEmpire/photo3.jpg'



const TradingPageImagesData = [
    {
        id: 1,
        src: comingsoon3,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon1,
    }, {
        id: 4,
        src: comingsoon4,
    }
    , {
        id: 5,
        src: comingsoon5,
    }
    
];
export default TradingPageImagesData;