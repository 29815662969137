import comingsoon1 from '../../assest/BootsEmpire/PestControlServicesImg1.jpeg'
import comingsoon2 from '../../assest/BootsEmpire/PestControlServicesImg2.jpg'
import comingsoon3 from '../../assest/BootsEmpire/PestControlServicesImg3.webp'
import comingsoon4 from '../../assest/BootsEmpire/PestControlServicesImg4.jpg'
import comingsoon5 from '../../assest/BootsEmpire/PestControlServicesImg5.jpg'
import comingsoon6 from '../../assest/BootsEmpire/PestControlServicesImg6.jpg'





const PestControlServicesImageData = [
    {
        id: 1,
        src: comingsoon6,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon5,
    }, {
        id: 4,
        src: comingsoon4,
    }
    , {
        id: 5,
        src: comingsoon3,
    },
    {
        id: 6,
        src: comingsoon1,
    },
];
export default PestControlServicesImageData;