


import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/sofaCleaningImg1.jpg'
import photo4 from '../../assest/BootsEmpire/FurnituresCleaningImg1.jpg'
import photo2 from '../../assest/BootsEmpire/PestControlServicesImg3.webp'
import photo3 from '../../assest/BootsEmpire/PestControlServicesBanner.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import SofaCarpetCleaningServicesImageData from "../../data/BootsEmpire/SofaCarpetCleaningServicesImageData"
import PestControlServicesData from "../../data/BootsEmpire/PestControlServicesData"
import OurPestControlProcessData from "../../data/BootsEmpire/OurPestControlProcessData"
import PestControlServicesImageData from "../../data/BootsEmpire/PestControlServicesImageData"



const PestControlServices = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Pest Control Services</h1>
                </div>
            </Box>


            <Box className='Boost_about_div text-start px-5'>
                <h1 className="text-start fs-2 pb-4">Complete Pest Control Services Kuwait</h1>
                <p className="pb-1 fs-5">If you are seeking for premium, professional and reasonable pest control services in Kuwait, Boost Empire General Trading & Contracting would be delighted to assist.</p>
                <p className="pb-1 fs-5">Our pest control company make sure that all anti-pest treatments are implemented in agreement with health and safety protocols of Kuwait. We utilize verified methods, specialized equipment and industry-grade products to fulfil both commercial and residential clienteles’ pest control requisites. We are able to offer speedy pest control services that means you can depend on our specialists who will function meticulously with you and deliver convenience together with outstanding customer support.</p>
                <p className="pb-1 fs-5">Relax and see us cleaning all the bug and make your home free from cockroach, ants, rats etc</p>
                <p className="pb-1 fs-5">The exterminators are skilled and equipped to examine, treat and guard both commercial establishments and residential properties against several kinds of pests. The pest control service team are on action mode even when you require a discreet help urgently and with no delay.</p>
                <p className="pb-1 fs-5">Some of our pest control services comprise of Termite pest control, Insect pest control, Cockroach treatment, Rat pest control, Bed Bug Control Services and many more. No matter how big or small the concern is, we will take care of it swiftly, effectively and professionally. </p>
            </Box>


            <Box className='Boost_about_div'>
              
                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo2} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <p className=" mb-4 fw-bold pt-0">Our portfolio of clients to whom we cater our commercial pest control services in Kuwait comprises of:</p>
                        <ul>
                            <li>Private residential estate and managing agents as well as social housing enterprises and local authorities.</li>
                            <li>Facilities Management companies and in-house facilities.</li>
                            <li>Managers on small and large-scale establishments.</li>
                            <li>Restaurant, Bars and Cafes</li>
                            <li>Hotels</li>
                            <li>Office canteen and kitchen area, storerooms (depending on the type of goods stored), general offices or even the car park or anywhere in commercial office space.</li>
                            <li>Factory and Warehouse</li>
                            <li>Housing Associations</li>
                            <li>Care Homes</li>
                            <li>Hospitality and leisure business, from shopping centres to leisure complexes and sports facilities and clubs</li>
                            <li>Schools, Colleges and nurseries.</li>
                        </ul>

                        {/* <Link to={''}> <Button className="morebtn">CONTACT US <MdChevronRight className=" ms-3 fs-4" /></Button></Link> */}
                    </div>

                </Box>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <h1>Your Office or Home</h1>
                <p className="pt-4 pb-1 fs-5">Our residential and commercial pest control services are able to respond to any pest emergency and will eradicate your infestation, rapidly and without hassle. Most housing pest problems need only one visit by our pest specialists, who will find and eliminate the infestation, and deliver direction on pest-proofing your home.</p>
                <p className="pt-4 pb-1 fs-5">We provide a comprehensive range of residential pest control services that include; rat/rodent control, termite control, spider control, bird control including bird proofing, cockroach treatment, bed bug control, flies/mosquito control, bee control, insect control, ants control, lizard control, ticks control and other menace-causing pests.</p>
            </Box>

            <div className="allpackages_div">
                <h1 className=" pt-0">We Specialize In</h1>
                <div className="packages_slide">
                    {PestControlServicesData?.map((item, index) => (
                        <div key={item.id} className="packages_life_Slider">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                            <h4>{item.heading}</h4>
                            <p className="px-2 py-3">{item.textInfo}</p>
                        </div>
                    ))}
                </div>
            </div>


            <div className="Expertise_div">
                <h1 className=" fw-bold py-3">Our Pest Control Process</h1>
                <div className="Expertise_slidediv">
                    {OurPestControlProcessData?.map((item, index) => (
                        <div key={item.id} className="Expertise_div_slide">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                            <h4>{item.heading}</h4>
                            <p>{item.textdetail}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={PestControlServicesImageData} title={""} />
            </div>

           
            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Call us Today</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default PestControlServices