


const PlumbingServicesData = [
    {
        id: 1,
        heading: " Drain Cleaning & Repairs",
        textdetail: "We offer professional drain cleaning and repairs by licensed plumbers. Our skilled technicians are trained to handle all types of drains, regardless of size or complexity.",
    },

    {
        id: 2,
        heading: "Gas Line Services",
        textdetail: "We provide a variety of services, including gas line installation and repair, gas line workmanship, gas line construction and leak detection throughout Kuwait.",
    },
    {
        id: 3,
        heading: "Commercial Plumbing",
        textdetail: "We provide commercial plumbing services to the oil and gas industry in Kuwait. We are a full-service plumbing contractor providing skilled trade labor, design and construction services in Kuwait.",
    },
    {
        id: 4,
        heading: "Remodeling Services",
        textdetail: "Plumbing Remodelling Services are our specialty, we can handle all your plumbing needs. All work is guaranteed by our master plumbers who can work in your home or at your place of business.",
    },
    {
        id: 5,
        heading: "Plumbing Services",
        textdetail: "Our Residential Plumbing Services consist of plumbing installations and repairs, water heaters and pumps, garbage disposals, bath tubs and sinks. We also can provide minor remodeling services such as drainage repair and installation.",
    },
    {
        id: 6,
        heading: "Heating & Cooling",
        textdetail: "We are specialized in providing professional Heating and Cooling solutions for residential, commercial, industrial and government installations anywhere in Kuwait.",
    },

];
export default PlumbingServicesData