import './BootsEmpireFooterStyle.css'
import Logo from "../../../assest/BootsEmpire/Whatsapp-Chat-Icon-1.webp";
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { MdLocationPin, MdCall, MdEmail } from "react-icons/md";

const BootsEmpireFooter = () => {
    const defalutValue = {
        EmailAddress: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        EmailAddress: yup.string().required('Email is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);
    }


    return (
        <>

            <div className="newfooter">
                <div className="">
                    <div className="footer_links">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="footerlnk-head">Company</div>
                                <ul className="menu_div">
                                    <li><Link to="/blogs">Blogs</Link></li>
                                    <li><Link to="/FAQ">FAQ</Link></li>
                                    <li><Link to="/privacy_policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms_conditions">Terms & Conditions</Link></li>
                                    <li><Link to="/photo_gallery">Photo Gallery</Link></li>
                                    <li><Link to="/video_gallery">Video Gallery </Link></li>
                                </ul>


                                <div className="footerlnk-head">Follow Us</div>
                                <ul className="soc_ul_footer">
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/fb.png" alt="Facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/in.png" alt="Instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/li.png" alt="LinkedIn" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/tw.png" alt="Twitter" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-3">
                                <div className="footerlnk-head">Our Services</div>
                                <ul className="menu_div">
                                    <li><Link to="/mechanical_works">Mechanical Works</Link></li>
                                    <li><Link to="/electrical_maintenance">Electrical Maintenance</Link></li>
                                    <li><Link to="/waterproofing_solutions">Waterproofing Solutions</Link></li>
                                    <li><Link to="/building_maintenance">Building Maintenance</Link></li>
                                    <li><Link to="/AC_services">AC Services</Link></li>
                                    <li><Link to="/plumbing_services">Plumbing Services</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <div className="footerlnk-head">Our Services</div>
                                <ul className="menu_div">
                                    <li><Link to="/contracting_work">Contracting Work</Link></li>
                                    <li><Link to="/painting_services">Painting Services</Link></li>
                                    <li><Link to="/kitchen_duct_cleaning">Kitchen Duct Cleaning</Link></li>
                                    <li><Link to="/deep_cleaning_services">Deep Cleaning Services</Link></li>
                                    <li><Link to="/sofa_carpet_cleaning">Sofa & Carpet Cleaning</Link></li>
                                    <li><Link to="/car_wash_services">Car Wash Services</Link></li>
                                
                                </ul>

                            </div>

                            <div className="col-md-3">
                                <div className="footerlnk-head">Corporate Office</div>
                                <ul className="menu_div">
                                    <li className="fw-bold">Boost Empire General Trading & Contracting Co. W.L.L</li>
                                    <li className=' mt-3'><MdLocationPin className=' me-2 fs-3' />Office No 2, 4th floor, Block 1, Street No.122, Kuwait-Farwaniya</li>
                                    <li className=' mt-3'><MdCall className=' me-2 fs-3' /><Link to="tel:+965 502 86435">+965 502 86435</Link></li>
                                    <li className=' mt-3'><MdEmail className=' me-2 fs-3' /><Link to="mailto:info@boostempires.com">info@boostempires.com</Link></li>
                                </ul>
                                <img className='whatsappimg' src={Logo} alt="img" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="copyright">
                <div className="copy">
                    <p>Copyright © 2024 Boost Empire General Trading & Contracting Co. W.L.L. Powered By <Link to={'https://www.abarissoftech.com/'}> Abaris Softech </Link></p>
                </div>
            </div>


        </>
    )
}

export default BootsEmpireFooter