
import polygon from '../../assest/BootsEmpire/about-us-page-banner.jpg'
import { Box, Button } from "@mui/material"
import '../../styles/BootsEmpire/AboutUsStyle.css'
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import BootsEmpireFooter from '../../common/BootsEmpire/Footer/BootsEmpireFooter'
import EnterpriseImg from '../../assest/BootsEmpire/slider-02-1.jpg'
import AboutExpertiseData from '../../data/BootsEmpire/AboutExpertiseData'
import { Link } from 'react-router-dom'




const AboutUs = () => {

    return (
        <>
            <BootsEmpireHeader />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>About Us</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle about_textdiv">
                <div className="">
                    <h4 className=' fw-bold'>One of the best Contracting Companies in Kuwait</h4>
                    <p className="">Boost Empire General Trading & Contracting Co. W.L.L, based in Kuwait, is renowned for its excellence in trading and MEP contracting. With a focus on premium products sourced globally, the company has earned acclaim for its commitment to quality. Specializing in MEP contracting, it contributes to Kuwait urbanization with innovative solutions. Boost Empire General Trading & Contracting integration of cutting-edge technologies and sustainable practices aligns with Kuwait developmental goals, making it a key player in the nation’s progress.</p>
                    <h4 className=' fw-bold'>Boost Empire General Trading & Contracting Co. W.L.L</h4>
                    <img src={EnterpriseImg} alt="img" />
                    <h4 className=' fw-bold my-2'>Welcome to Boost Empire General Trading & Contracting, Your Number One Solution for MEP Contracts</h4>
                    <p>Established in 2017, Boost Empire General Trading & Contracting, based in Kuwait, excels in trading, MEP contracting, cleaning, and hospitality services. Our trading and MEP contracting divisions provide diverse solutions, from procurement to project execution, backed by a skilled team and a strong client base. We specialize in delivering clean, hygienic environments through advanced cleaning techniques and quality products for commercial, residential, and hospitality spaces.</p>
                    <p>Our dedicated professionals, from engineers to hospitality staff, ensure excellence in every project. Customer satisfaction is our priority, achieved through tailored solutions, transparency, and fostering long-term relationships. Committed to core values of excellence and integrity, we aim to be Kuwait preferred choice for trading, MEP contracting, cleaning, and hospitality services.</p>
                    <p>Contact us for more information or to discuss your project needs. We’re eager to partner with you and provide exceptional solutions.</p>
                    {/* <p>LearHoliday.</p> */}
                </div>
            </div>

            <div className="TradingCompany_div">
                <h1 className=" fw-bold">Organizational Ethos</h1>
                <div className="row gap-1 Trading_slidediv">
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 className=' text-start'>Company Vision</h4>
                        <p className=' text-start'>Boost Empire General Trading & Contracting is dedicated to continual innovation, striving to position itself as the premier provider of cleaning and logistical human resources in Kuwait. Our commitment lies in fostering strong partnerships with our clients and stakeholders, anchored on principles of trust and reliability.</p>
                    </div>
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 className=' text-start'>Company Mission</h4>
                        <p className=' text-start'>Maintaining cleanliness in spaces such as apartments, offices, and multiplexes is undoubtedly a demanding task. At Boost Empire General Trading & Contracting, we recognize this challenge and are committed to providing a comprehensive solution. With a simple phone call, you can entrust us with the responsibility of ensuring the cleanliness of your premises. Our services are tailored to deliver precision and reliability, not just for the present, but also for the future. As we strive to become synonymous with excellence in cleaning services, your satisfaction remains our primary goal.</p>
                    </div>
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 className=' text-start'>Our Values</h4>
                        <p className=' text-start'>At our core, we embody Respect, Responsibility, Resilience, and Relentlessness. These values guide our every action as we strive to become the leading cleaning and logistic workforce supply company. Our unwavering commitment to integrity resonates deeply with our customers, speaking volumes about our dedication to excellence.</p>
                    </div>
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 className=' text-start'>Company Goals</h4>
                        <p className=' text-start'>Our mission is to ensure the entire process is effective and efficient, delivering optimal results for our clients.</p>
                        <p className=' text-start'>We are dedicated to establishing a culture of continuous improvement, striving for excellence in all aspects of our operations.</p>
                        <p className=' text-start'>Fostering productive and positive relationships with our clients is at the heart of our mission, driving satisfaction and long-term partnerships.</p>
                        <p className=' text-start'>Our mission is to deliver high-quality services at competitive market prices, providing value and reliability to our clients.</p>
                        <p className=' text-start'>We are committed to maintaining consistent quality standards across all our services, ensuring reliability and trustworthiness.</p>
                        <p className=' text-start'>Our mission is to promptly provide additional manpower during emergency situations, demonstrating our commitment to client support and reliability.</p>
                        <p className=' text-start'>We innovate and adapt to meet the evolving needs of our clients, staying ahead of industry trends and expectations.</p>
                    </div>
                </div>
            </div>

            <div className="Expertise_div">
                <h1 className=" fw-bold py-3">Expertise</h1>
                <div className="Expertise_slidediv">
                    {AboutExpertiseData?.map((item, index) => (
                        <div key={item.id} className="Expertise_div_slide">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                            <h4>{item.heading}</h4>
                            <p>{item.textdetail}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="PopularTours_div">
                <Link to={'/contact_us'}> <Button className="butnn">Contact Us</Button></Link>
                <p className=' text-white pt-4 fs-5 mx-5'>Effective housekeeping responsibilities, top MEP contractors in Kuwait, and professional pest control services for comprehensive facility management solutions</p>
            </div>


            <BootsEmpireFooter />

        </>
    )
}

export default AboutUs