

import polygon from '../../assest/BootsEmpire/about-us-page-banner.jpg'
import { Box } from "@mui/material"
import '../../styles/BootsEmpire/AboutUsStyle.css'
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import BootsEmpireFooter from '../../common/BootsEmpire/Footer/BootsEmpireFooter'
import EnterpriseImg from '../../assest/BootsEmpire/slider-02-1.jpg'




const TermsConditions = () => {

    return (
        <>
            <BootsEmpireHeader />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Terms & Conditions</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle about_textdiv">
                <div className="">
                    <p className=" fw-bold">Terms & Conditions</p>
                    <p>The Website Owner, including subsidiaries and affiliates (“Website” or “Website Owner” or “we” or “us” or “our”) provides the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website.</p>
                    <p>Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Boost Empire General Trading & Contracting relationship with you in relation to this website.</p>
                    <p>The term Boost Empire General Trading & Contracting or 'us' or 'we' refers to the owner of the website whose registered office is Flat No 6 , Shankar Market , Connaught Place , New Delhi -110001 , India. The term 'you' refers to the user or viewer of our website.</p>
                    <p>The use of this website is subject to the following terms of use:</p>
                    <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>
                    <p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>
                    <p>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</p>
                    <p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
                    <p>All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</p>
                    <p>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.</p>
                    <p>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</p>
                    <p>You may not create a link to this website from another website or document without [business name]'s prior written consent.</p>
                    <p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.</p>
                    <p>"We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time"</p>
                    <p className=" fw-bold">How we use cookies</p>
                    <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyses web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
                    <p>We use traffic log cookies to identify which pages are being used. This helps us analyses data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
                    <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
                    <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
                    
                    <p className=" fw-bold">BO Clause</p>
                    <p>We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</p>

                </div>
            </div>


            <BootsEmpireFooter />

        </>
    )
}

export default TermsConditions