import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box, Button } from "@mui/material";
import TopBannersData from "../../data/BootsEmpire/TopBannersData";
import '../../styles/BootsEmpire/MainHomeStyle.css'
import img1 from '../../assest/BootsEmpire/peace-qatar-scaled.jpg'
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import OurDivissionData from "../../data/BootsEmpire/OurDivissionData";
import TradingAndContactingServicesData from "../../data/BootsEmpire/TradingAndContactingServicesData";
import FacilityServicesData from "../../data/BootsEmpire/FacilityServicesData";
import CivilWorkData from "../../data/BootsEmpire/CivilWorkData";
import SustainabilityData from "../../data/BootsEmpire/SustainabilityData";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurClientData from "../../data/BootsEmpire/OurClientData";
import ClientSatisfactionData from "../../data/BootsEmpire/ClientSatisfactionData";
import ClientTestimonialData from "../../data/BootsEmpire/ClientTestimonialData";
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ThankYouDialoge from "../../components/ThankYouDialoge";
import imgConstruction1 from '../../assest/BootsEmpire/Construction.jpg'




const MainHome = () => {

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };


    const [EnquiryData, SetEnquiryData] = useState({
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: '',
    })

    const [errorName, setErrorName] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorMobile, setErrorMobile] = useState("");
    const [bordrColor, setBordrColor] = useState("");

    const navigate = useNavigate()

    const onInputChnge = (e) => {
        const inpName = e.target.name;
        const inpVal = e.target.value;
        const cloned = { ...EnquiryData }
        cloned[inpName] = inpVal;
        SetEnquiryData(cloned);
    }


    const onSendClick = async (e) => {
        e.preventDefault();

        try {
            // Reset errors and color
            setErrorName("");
            setErrorEmail("");
            setErrorMobile("");
            setBordrColor("");

            // Perform input validation here before making the API call
            if (
                EnquiryData.name.length > 2 &&
                EnquiryData.email.includes("@") &&
                EnquiryData.mobile.length === 10 &&
                EnquiryData.subject.length > 2 &&
                EnquiryData.message.length > 2
            ) {
                setBordrColor('1px solid green');
                const sign_up = await axios.post(
                    'https://backoffice.adamclick.com/api/auth/lead/register',
                    { ...EnquiryData }
                );
                SetEnquiryData(sign_up.data);
                console.log(sign_up.data);

                // Clear the form fields
                SetEnquiryData({
                    name: '',
                    email: '',
                    mobile: '',
                    subject: '',
                    message: '',
                });

                setOpen(true);
                // Provide user feedback or navigation here if needed
                // navigate('/contact')
                console.log('Form data submitted successfully!');
            } else {
                if (EnquiryData.name.length <= 2 || EnquiryData.name === '') setErrorName("enter name");
                if (!EnquiryData.email.includes("@") || EnquiryData.email === '') setErrorEmail("enter email");
                if (EnquiryData.mobile.length !== 10 || EnquiryData.mobile === '') setErrorMobile("enter valid 10 digit number");

                setBordrColor('1px solid white');
                // Provide user feedback for validation errors
                console.log('Form validation failed');
            }
        } catch (error) {
            // Provide user-friendly error message
            console.log('Error submitting form:', error.message);
        }
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 4,
                },
            }
        ],
    };
    return (
        <>
            <BootsEmpireHeader />

            <Box id="BoxId">
                <Swiper
                    centeredSlides={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    className=""
                    style={{ zIndex: -1 }}
                >
                    {TopBannersData?.map(({ id, name, src }) => {
                        return (
                            <SwiperSlide key={id} className="">
                                <div className="custom-img">
                                    <img src={src} alt="img" />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>

            <Box className='Boost_about_div'>
                <h1>About Us</h1>
                <h2>Boost Empire General Trading & Contracting Co. W.L.L</h2>
                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={img1} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <h6>Best MEP Contractor Kuwait</h6>
                        <p>In Kuwait, Boost Empire General Trading & Contracting - a trading and MEP contracting company is a leading provider of multiple building and construction related services.  Our staff are well-trained and skilled. Their experienced eyes are critical in ensuring a detailed work of the place. Our success is because of our service staff and the commitment with which they serve each client.</p>
                        <p>Whether it’s commercial spaces, residential properties, or hospitality establishments, our skilled team utilizes advanced techniques and high-quality services.</p>
                        <p>We provides services all over Kuwait.</p>
                        <Link to={'/about_us'}><Button className="morebtn">Read More <FaSquareArrowUpRight className=" ms-3 fs-4" /></Button></Link>
                    </div>
                </Box>
            </Box>


            <div className="TradingCompany_div">
                <h1 className=" fw-bold">TRADING AND MEP CONTRACTING COMPANY</h1>
                <div className="row gap-1 Trading_slidediv">
                    <div className=" col-lg-10 col-12 Trading_slide">
                        <p>Boost Empire General Trading & Contracting Company is a prominent organization based in Kuwait that specializes in providing a comprehensive range of services, including civil work, contracting and trading, and facility management for both villa and commercial spaces. With a solid foothold in the Kuwait market, Boost Empire General Trading & Contracting is dedicated to delivering exceptional solutions to meet the diverse needs of their clients.</p>
                    </div>
                    <div className=" col-lg-10 col-12 Trading_slide">
                        <p>Furthermore, Boost Empire General Trading & Contracting excels in facility management services for both villas and commercial spaces. Their facility management division focuses on maintaining and managing properties to ensure their optimal functioning. This encompasses a wide range of services such as maintenance and repair work, energy management, security services, landscaping, and other related tasks. By providing comprehensive facility management solutions, Boost Empire General Trading & Contracting aims to alleviate the burden of property owners, allowing them to focus on their core activities while enjoying well-maintained and efficient properties.</p>
                    </div>
                    <div className=" col-lg-10 col-12 Trading_slide">
                        <p>Boost Empire General Trading & Contracting is committed to upholding the highest standards of professionalism and customer satisfaction. They prioritize understanding their clients’ unique requirements and tailor their services accordingly. With a customer-centric approach, the company aims to forge long-term partnerships and build strong relationships based on trust, reliability, and exceptional service delivery.</p>
                    </div>
                    <div className=" col-lg-10 col-12 Trading_slide">
                        <p>Moreover, Boost Empire General Trading & Contracting places great importance on adherence to safety standards and environmental sustainability. They prioritize the well-being of their employees, contractors, and the communities they operate in, implementing strict safety protocols and promoting sustainable practices throughout their operations.</p>
                    </div>
                </div>
            </div>

            {/* <Box className='sectors_weworkIn bg-black'>
                <h1 className=" fw-bold py-5 text-center text-white">TRADING & CONTRACTING SERVICES</h1>

                <div className="row justify-content-center gap-4">

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgConstruction1} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>Contracting Work</h1>
                                <p>Looking for a reliable Plumber in Kuwait? No matter what type of plumbing services you</p>
                                <Button>Read More <FaSquareArrowUpRight className=" ms-3 fs-4" /></Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgConstruction1} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>Contracting Work</h1>
                                <p>Looking for a reliable Plumber in Kuwait? No matter what type of plumbing services you</p>
                                <Button>Read More <FaSquareArrowUpRight className=" ms-3 fs-4" /></Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={imgConstruction1} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>Contracting Work</h1>
                                <p>Looking for a reliable Plumber in Kuwait? No matter what type of plumbing services you</p>
                                <Button>Read More <FaSquareArrowUpRight className=" ms-3 fs-4" /></Button>
                            </div>
                        </div>
                    </div>
                </div>


            </Box> */}

            <div className="allpackages_div">
                <h1>OUR BUSINESS DIVISION</h1>
                <div className="packages_slide">
                    {OurDivissionData?.map((item, index) => (
                        <div key={item.id} className="packages_life_Slider">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                            <h4>{item.heading}</h4>
                        </div>
                    ))}
                </div >
            </div >

            <div className="PopularTours_div">
                <h1 className=" fw-bold py-3">TRADING & CONTRACTING SERVICES</h1>
                <div className="tour_slide">
                    {TradingAndContactingServicesData?.map((item, index) => (
                        <div className="populartour_Slider">
                            <div key={item.id} className="">
                                <Link to={`/${item.linkTag}`}>
                                    <img src={item.imgSrc} alt={`img-${item.id}`} />
                                    <h4>{item.heading}</h4>
                                </Link>

                                <p>{item.textdetail}</p>
                            </div>
                            <Link to={`/${item.linkTag}`}><Button className="booknowbtn" fullWidth>Read More <FaSquareArrowUpRight className=" ms-3 fs-4" /></Button></Link>
                        </div>
                    ))}
                </div >
                {/* <Button className="butnn">All Trading & MEP Contracting Services <FaSquareArrowUpRight className=" ms-3 fs-4" /></Button> */}
            </div >

            <div className="Civil_div">
                <h1 className=" fw-bold py-3">CIVIL WORKS</h1>
                <div className="Civil_slide">
                    {CivilWorkData?.map((item, index) => (
                        <div className="Civil_Slider">
                            <div key={item.id} className="">
                                <Link to={`/${item.linkTag}`}>
                                    <img src={item.imgSrc} alt={`img-${item.id}`} />
                                    <h4>{item.heading}</h4>
                                </Link>

                                <p>{item.textdetail}</p>
                            </div>
                            <Link to={`/${item.linkTag}`}>
                                <Button className="booknowbtn" fullWidth>Read More <FaSquareArrowUpRight className=" ms-3 fs-4" /></Button>
                            </Link>

                        </div>
                    ))}
                </div >
                {/* <Button className="butnn">All Civil Work Services <FaSquareArrowUpRight className=" ms-3 fs-4" /></Button> */}
            </div >

            <div className="Civil_div">
                <h1 className=" fw-bold py-3">FACILITY MANAGEMENT SERVICES</h1>
                <div className="Civil_slide">
                    {FacilityServicesData?.map((item, index) => (
                        <div className="Civil_Slider">
                            <div key={item.id} className="">
                                <Link to={`/${item.linkTag}`}>
                                    <img src={item.imgSrc} alt={`img-${item.id}`} />
                                    <h4>{item.heading}</h4>
                                </Link>
                                <p>{item.textdetail}</p>
                            </div>
                            <Link to={`/${item.linkTag}`}>
                                <Button className="booknowbtn" fullWidth>Read More <FaSquareArrowUpRight className=" ms-3 fs-4" /></Button>
                            </Link>
                        </div>
                    ))}
                </div>
                {/* <Button className="butnn"> All Facility Services <FaSquareArrowUpRight className=" ms-3 fs-4" /></Button> */}
            </div>

            <div className="Sustainability_div">
                <h1 className=" fw-bold py-3">SUSTAINABILITY</h1>
                <div className="Sustainability_slidediv">
                    {SustainabilityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="Sustainability_div_slide">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                                <p>{item.textdetail}</p>
                            </div>
                        </Link>

                    ))}
                </div>
            </div>

            <Box className='logistic_clients'>
                <h1>OUR SATISFIED CLIENTS</h1>
                <div className="our_partner">
                    <Slider {...settings}>
                        {OurClientData?.map((item, index) => (
                            <div key={item.id} className="Projects_topimg">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </Box>

            <div className="Satisfaction_div">
                <h1 className=" fw-bold py-3">CLIENTS  SATISFACTION</h1>
                <div className="Satisfaction_slidediv">
                    {ClientSatisfactionData?.map((item, index) => (
                        <div key={item.id} className="Satisfaction_div_slide">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                            <h4>{item.heading}</h4>
                        </div>
                    ))}
                </div>
            </div>

            <section id="testimonials">
                <h2 className="testimonials_h2">What our client’s are saying...</h2>
                <Swiper
                    centeredSlides={false}
                    spaceBetween={30}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    className="container testimonials_container"
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}
                >
                    {ClientTestimonialData.map(({ id, name, review, image }) => {
                        return (
                            <SwiperSlide key={id} className="testimonial">
                                <div className="client_avatar">
                                    <img src={image} alt="img" />
                                </div>
                                <p className="client_review">
                                    {review}
                                </p>
                                <h5 className="client_name"> - {name}</h5>

                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </section>


            <div className="">
                <section className="contact-us section1">
                    <h1 className=" text-center fw-bold text-white pt-5">Get connected with us</h1>
                    <div className="">
                        <div className="inner">
                            <div className="row justify-content-center py-5">
                                <div className="col-lg-6 card-color shadow"
                                    style={{ background: "", borderRadius: "5px 0 0 5px", border: '1px solid white' }}
                                >
                                    <div className="contact-us-form">
                                        <h5 className=" text-white">
                                            If you have any questions please fell free to contact with
                                            us.
                                        </h5>
                                        <form className="form">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            onChange={onInputChnge}
                                                            value={EnquiryData.name}
                                                            style={{ border: bordrColor, borderRadius: '4px' }}
                                                            placeholder="Name"
                                                            required=""
                                                        />
                                                        <p style={{ color: 'red', fontSize: '12px' }} className="mb-0">{errorName}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            onChange={onInputChnge}
                                                            value={EnquiryData.email}
                                                            style={{ border: bordrColor, borderRadius: '4px' }}
                                                            placeholder="Email"
                                                            required=""
                                                        />
                                                        <p style={{ color: 'red', fontSize: '12px' }} className="mb-0">{errorEmail}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            onChange={onInputChnge}
                                                            value={EnquiryData.mobile}
                                                            style={{ border: bordrColor, borderRadius: '4px' }}
                                                            placeholder="Phone"
                                                            required=""
                                                        />
                                                        <p style={{ color: 'red', fontSize: '12px' }} className="mb-0">{errorMobile}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="subject"
                                                            onChange={onInputChnge}
                                                            value={EnquiryData.subject}
                                                            placeholder="Subject"
                                                            required=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <textarea
                                                            name="message"
                                                            onChange={onInputChnge}
                                                            value={EnquiryData.message}
                                                            placeholder="Your Message"
                                                            required=""
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <Button fullWidth onClick={onSendClick} className="btnbtnbgprimary" type="submit">
                                                            Send
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className=' col-lg-6 col-12 p-1'
                                    style={{ background: "", borderRadius: "0 5px 5px 0", border: '1px solid white' }}
                                >
                                    <iframe
                                        className=""
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13919.610483868208!2d47.94189139761757!3d29.285187747872026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf9a34dd39885b%3A0xa5e6bfe6ccd5940a!2sBlock%201%2C%20Al%20Farwaniyah%2C%20Kuwait!5e0!3m2!1sen!2sin!4v1727360437103!5m2!1sen!2sin"
                                        width="100%"
                                        height="100%"
                                        style={{ border: 0 }}
                                        allowfullscreen=""
                                        loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>

            <ThankYouDialoge open={open} setOpen={setOpen} handleClose={handleClose} />

            <BootsEmpireFooter />
        </>
    )
}

export default MainHome