import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/WaterTankCleaningBannerImage.jpg'
import photo2 from '../../assest/BootsEmpire/Water Tank Cleaning.webp'
import photo3 from '../../assest/BootsEmpire/WaterTankCleaningImage3.jpg'
import photo4 from '../../assest/BootsEmpire/WaterTankCleaningImage4.webp'
import photo5 from '../../assest/BootsEmpire/WaterTankCleaningImage2.webp'
import photo6 from '../../assest/BootsEmpire/GreaseTrapCleaningImg4.webp'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import GreaseTrapCleaningImageData from "../../data/BootsEmpire/GreaseTrapCleaningImageData"
import photo10 from '../../assest/BootsEmpire/WatertankCleaningbefore-after.jpg'
import WaterTankCleaningImageData from "../../data/BootsEmpire/WaterTankCleaningImageData"




const WaterTankCleaning = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo1} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Water Tank Cleaning</h1>
                </div>
            </Box>




            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <h1>Professional Water Tank Cleaning in Kuwait</h1>
                        <p className="pb-1 fs-5">To have a healthy life, it is crucial that one drinks clean water and even use clean water for other works too. Since in the modern world we store water in water tanks both in commercial and residential properties cleaning and disinfecting it after regular intervals is vital. Boost Empire General Trading & Contracting is a water tank cleaning service company in Kuwait that ensures your water tank is clean and safe.</p>
                        <p className="pb-1 fs-5">In many places, usage of contaminated water has resulted in causing major illnesses, including causing epidemics. It has been estimated that 60% of the diseases that humans suffer from are water-borne, and mainly children under the age of 5 years are more susceptible to them. Often the cause of people using contaminated water is the result of using water from an uncleaned water storage tank.  Much neglect getting a thorough cleaning of the tank regularly or often try to save money by hiring a local unprofessional cleaner or sweeper to do the job.</p>

                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>

                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo2} alt="img" />
                        <img className=" bg-black h-auto mt-3" src={photo3} alt="img" />
                    </div>

                </Box>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <h1 className=" fw-bold">Boost Empire General Trading & Contracting provide water tank cleaning service in Kuwait</h1>
                <img className=" h-25 w-100  my-5" src={photo10} alt="img" />
                <p className="pb-1 fs-5">The result is not having a thorough clean and the infecting materials staying back in the tank.</p>
                <p className="pb-1 fs-5">Boost Empire General Trading & Contracting water tank cleaning service in Kuwait is a professional company in the sector. A water tank that has water provides an ideal environment for the growth of harmful bacteria, insects, reptiles, decayed water vegetation and even dead animals. All these can cause life-threatening diseases to people using water from such water tanks.</p>
                <p className="pb-1 fs-5">In order to have a healthy life, every person should be responsible for getting the water tank cleaned after regular intervals. An uncleaned water tank is not only a place to harbour all above-mentioned fatal elements, but it also can harbour dangerous bacteria like Legionella. An uncleaned water tank, reservoirs and even cooling tanks have a high risk of spreading the diseases that are caused by such bacteria.</p>
                <p className="pb-1 fs-5">To ensure that you have a healthy is to have proper maintenance of the water-tank and hire professionals who know the job of how to clean the tank properly. Boost Empire General Trading & Contracting is a water tank cleaning service in Kuwait who has professional water tank cleaners with proper equipment, material and non-toxic, bio-degradable, safe chemicals. Cleaning and disinfecting water tanks on a regular basis is vital, mainly for people who reside in apartments complexes, works in commercial buildings, and even restaurants. It is the duty of the property manager or the owner of the water tank to keep the tank clean regularly and take all the preventive measures so that the water tank does not get contaminated.</p>
            </Box>

            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <h1>Benefits of Does Water Tank Cleaning</h1>
                        <p className="pb-1 fs-5">Keeps the water clean and fresh</p>
                        <ul className="pt-3">
                            <li>Ensures there is no sediment and silt</li>
                            <li>Increases the longevity of the tank</li>
                            <li>Reduces the maintenance cost of the tank</li>
                            <li>Averts leakages and blockages that can lead to shut-offs</li>
                            <li>An outbreak of water-borne diseases can be prevented</li>
                            <li>Prevents other damages of valves and pipes that are caused due to rust</li>
                        </ul>

                    </div>

                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo4} alt="img" />
                    </div>
                </Box>
            </Box>


            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo5} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <h1>What We Offer</h1>
                        <ul>
                            <li>Drain the tank</li>
                            <li>Dislodge and then remove all the residue</li>
                            <li>Scrub, disinfect and pressure-wash the entire surfaces of the tank</li>
                            <li>Dry the tank and then refill it</li>
                        </ul>
                        <p className="py-1 fs-6">We are in the compliance with the regulations of the water tank cleaning services in Kuwait.</p>
                        <p className="py-1 fs-6">What is often present in uncleaned water tanks?</p>
                        <ul className=" pt-3">
                            <li>Scale (calcium deposition)</li>
                            <li>Algae</li>
                            <li>Sediment</li>
                            <li>Rust and corrosion</li>
                            <li>Organic matter and biofilm</li>
                            <li>Stagnation</li>
                            <li>Debris</li>
                            <li>Flora ingress</li>
                            <li>Pest and insect ingress</li>
                        </ul>

                    </div>
                </Box>
            </Box>


            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us?</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>

            <div className=" bg-black">
                <GalleryPhotos GalleryData={WaterTankCleaningImageData} title={""} />
            </div>


            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Contact us for any type of water tank cleaning service in Kuwait</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default WaterTankCleaning