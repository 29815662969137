
const ContractingWorkServicesData = [
    {
        id: 1,
        heading: "Hotel Apartments",
        textdetail: "Leading Kuwait contractor for Hotel Apartments: Experts in turnkey construction & maintenance. Proven track record of delivering upscale projects on time and budget. Skilled in design, MEP, and interiors, ensuring top-notch quality. ",
    },

    {
        id: 2,
        heading: "Office Building",
        textdetail: "Transforming visions into reality, we specialize in crafting and maintaining exceptional office buildings across Kuwait. Our expertise ensures impeccable design, construction, and upkeep, reflecting professionalism and innovation. Elevate your workspace with us.",
    },
    {
        id: 3,
        heading: "Residential Villas",
        textdetail: "Crafting exquisite residential villas is our forte in the Kuwait. From concept to creation, we ensure luxurious living spaces with meticulous attention to detail. Trust us for construction and maintenance that redefine comfort and elegance. Your dream villa awaits.",
    },
    {
        id: 4,
        heading: "Warehouses",
        textdetail: "We offer top-tier Warehouse construction and maintenance services in the Kuwait. Our skilled team ensures efficient project execution, adhering to local regulations. From design to completion, we guarantee quality, timely delivery, and cost-effectiveness. Your ideal Warehousing solution starts here.",
    },

];
export default ContractingWorkServicesData