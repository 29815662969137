import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import GallerData from "../../data/BootsEmpire/GallerData"
import { Box } from "@mui/material"
import polygon from '../../assest/BootsEmpire/blogsbanner.jpg'

const PhotoGallery = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1 style={{ zIndex: -33 }}>Photo Gallery</h1>
                </div>
            </Box>

            <GalleryPhotos GalleryData={GallerData} title={"Photo Gallery"} />

            <BootsEmpireFooter />

        </>
    )
}

export default PhotoGallery