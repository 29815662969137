
import comingsoon1 from '../../assest/BootsEmpire/PaintingServicesImg1.jpg'
import comingsoon2 from '../../assest/BootsEmpire/PaintingServicesImg2.webp'
import comingsoon3 from '../../assest/BootsEmpire/PaintingServicesImg3.jpg'
import comingsoon4 from '../../assest/BootsEmpire/PaintingServicesImg4.webp'
import comingsoon5 from '../../assest/BootsEmpire/PaintingServicesImg5.jpg'



const PaintingServicesImageData = [
    {
        id: 1,
        src: comingsoon5,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon1,
    }, {
        id: 4,
        src: comingsoon3,
    }
    , {
        id: 5,
        src: comingsoon4,
    }
   
    
];
export default PaintingServicesImageData;