
import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/CarWashServicesImage3.jpg'
import photo2 from '../../assest/BootsEmpire/CarWashServicesImage6.jpg'
import photo4 from '../../assest/BootsEmpire/CarWashServicesImage5.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import CarWashServicesImageData from "../../data/BootsEmpire/CarWashServicesImageData"




const CarWashServices = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo1} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Car Wash Services</h1>
                </div>
            </Box>


            <Box className='Boost_about_div text-start'>
                <h1 className=" fw-bold px-5 pt-5">CAR CLEANING & SHINING KUWAIT</h1>
                <p className="px-5 fs-5 pb-5">People who have cars, they are just not four wheels and a machine to move around in, but it is like a baby to them. So keeping them clean is quite important to many, but with the busy lifestyle that we have, often we fail to clean and wash them regularly. How would you feel if you have someone who would take care of your car and a specialist who would clean and wash it with all the care?</p>

                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        {/* <h1>Professional Water Tank Cleaning in Kuwait</h1> */}
                        <p className="pb-1 fs-5">At Boost Empire General Trading & Contracting, we offer car wash service in Kuwait. We are one of the best quality driven car wash companies in Kuwait who gives the full-service to our clients.</p>
                        <p className="pb-1 fs-5">Boost Empire General Trading & Contracting car wash service in Kuwait strives to provide you with the best service that is speedy, of exceptional quality, detailed while minimizing the cost of the wash.</p>

                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>

                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo2} alt="img" />
                    </div>

                </Box>


            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <p className="pb-1 fs-5">We have a team of highly dedicated staff, who are well-trained in the profession. They are equipped to wash off even the toughest stains without harming the color, upholstery and the fabric of the car.</p>
                <p className="pb-1 fs-5">We ensure that the materials and the chemicals used for the wash are of the best quality so that it gives the brightest shine to your car at an affordable price.</p>
            </Box>

            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo4} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <h1>What We Offer</h1>
                        <p className="pb-1 fs-5">Being one of the best car wash services in Kuwait, we offer the following services to our clients:</p>
                        <ul className="pt-3 fs-5">
                            <li>A detailed car-wash service that is of high quality but at an affordable price</li>
                            <li>The tools, equipment and materials used are of the latest technology in the market</li>
                            <li>The chemicals used are bio-friendly and non-corrosive, so they do not damage your car in any area</li>
                            <li>Boost Empire General Trading & Contracting ensures that after the wash you get the shiniest and cleanest car in the area that smells fresh too</li>
                            <li>Our car wash package does not comprise only of washing the exterior of the car, but we do a detailed cleaning of the interior too, which includes dusting, vacuuming and when required shampooing of the upholstery. We also take special care of the leather seats, so they stay in perfect shape</li>
                            <li>We also promise a Hassel free and convenient car wash service without standing in long queuing in any centre.</li>
                        </ul>
                    </div>
                </Box>
            </Box>



            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us?</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>

            <div className=" bg-black">
                <GalleryPhotos GalleryData={CarWashServicesImageData} title={""} />
            </div>


            <Box className='Boost_about_div text-start px-5'>
                <p className="pb-1 fs-5">There are many car wash facilities in Kuwait, but what sets us apart from the others is our experience and dedication. We not only remove the tough stains from the body but also use active foam emulsion, especially for the exterior to wash your car. We next, dry your car with micro-fiber towels, so the body of the car does not get any scratches.</p>
                <p className="pb-1 fs-5">Despite offering you an affordable price, we do not compromise on the quality. To give your car the best shine, we do a hand polishing of the full body of the car with liquid wax. Boost Empire General Trading & Contracting as the provider of your car wash services in Kuwait, we wash the tires and wheels of the car individually and thoroughly. For your benefit we also check them.</p>
                <p className="pb-1 fs-5">If you are looking for someone to take care of your car and clean it to perfection then without further hesitation, call us now. We will make sure you get a brand new looking, shiny car after the wash at an affordable price.</p>
            </Box>



            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Contact us to clean your car</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default CarWashServices