
import people1 from '../../assest/BootsEmpire/sofa-cleaning.jpg'
import people2 from '../../assest/BootsEmpire/Pest Control Services.jpg'
import people3 from '../../assest/BootsEmpire/Water Tank Cleaning.webp'
import people4 from '../../assest/BootsEmpire/Deep Cleaning Services.jpg'



const FacilityServicesData = [
    {
        id: 1,
        heading:'Sofa & Carpet Cleaning',
        textdetail:'We are professional sofa, carpet & upholstery cleaning company that is here to help you',
        linkTag: 'sofa_carpet_cleaning',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Pest Control Services',
        textdetail:'Complete Pest Control Services Kuwait If you are seeking for premium, professional and reasonable pest',
        linkTag: 'pest_control_services',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Water Tank Cleaning',
        textdetail:'Professional Water Tank Cleaning in Kuwait To have a healthy life, it is crucial that',
        linkTag: 'water_tank_cleaning',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'Deep Cleaning Services',
        textdetail:'Deep Cleaning Services in Kuwait Deep Cleaning Services at your Door Step We are providing',
        linkTag: 'deep_cleaning_services',
        imgSrc: people4,
    }
   
   
];
export default FacilityServicesData