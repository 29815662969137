
import people1 from '../../assest/BootsEmpire/office-cleaning-services.webp'
import people2 from '../../assest/BootsEmpire/home-cleaning.jpg'
import people3 from '../../assest/BootsEmpire/Shop-Deep-Cleaning.webp'
import people4 from '../../assest/BootsEmpire/Building_Cleaningjpg.jpg'



const CleaningServicesData = [
    {
        id: 1,
        heading:'Office',
        textInfo:'Looking for an office cleaning service in Kuwait? Get the best and efficient services of a professional cleaning company.',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'House',
        textInfo:'We are the best house cleaning company in Kuwait. Our team is professional and friendly, they always provide the best service for you!',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Shops',
        textInfo:'We offer professional shop cleaning and restoration services to businesses, commercial and residential properties.',
        imgSrc: people3,
    },
    {
        id: 4,
        heading:'Building',
        textInfo:'We provide deep and effective cleaning services for residential, commercial and industrial properties, offices, hotels etc',
        imgSrc: people4,
    }
   
];
export default CleaningServicesData