

import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/PaintingServicesImg1.jpg'
import photo2 from '../../assest/BootsEmpire/PaintingServicesImg2.webp'
import photo3 from '../../assest/BootsEmpire/PaintingServicesBanner.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import PaintingServicesData from "../../data/BootsEmpire/PaintingServicesData"
import PaintingServicesImageData from "../../data/BootsEmpire/PaintingServicesImageData"


const PaintingServices = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Painting Services</h1>
                </div>
            </Box>



            <Box className='Boost_about_div text-start px-5'>
                <h1>Internal & External Painting Services in Kuwait</h1>
                <p className=" fs-5"> Discover comprehensive painting solutions in Kuwait that breathe new life into both external and internal spaces. Our expert teams meticulously revitalize facades, enhancing curb appeal and protecting structures against the region’s diverse climatic challenges. Inside, we masterfully blend colors and textures, crafting atmospheres that reflect your vision while optimizing each space’s functionality. With an unwavering commitment to quality, durability, and aesthetic finesse, our external and internal painting services elevate residential, commercial, and industrial properties across the Kuwait. Step into a world of transformative artistry that redefines aesthetics.</p>
                <p className=" fs-5"> For over many years, we have been offering external and internal painting services for residential and commercial homes and offices in Kuwait. We provide you with all the types of paintings with certified staff that has professional experience. </p>
                <p className=" fs-5"> Furthermore, we undertake and painting contractors of all industrial, commercial, and decorating projects all over the Kuwait. We just need to trust us! </p>
                <p className=" fs-5"> Not only do we specialize in painting homes of all sizes, inside and out, but we do also offer all the interior and exterior painting with a team of excellent quality painters.</p>
                <p className=" fs-5"> Furthermore, we use the best quality paints so that your house and office look more beautiful. In addition to beautiful looks, the colors we use are more durable and long-lasting.</p>
                <p className=" fs-5"> Our company provides services in all of Kuwait. You can call us anytime and we will be there to offer you our services.</p>
                <p className=" fs-5"> Our rates will also astonish you. Because we aim to provide good and long-lasting paint services at reasonable and competitive rates in Kuwait.</p>
            </Box>

            <div className=" bg-black">
                <GalleryPhotos GalleryData={PaintingServicesImageData} title={""} />
            </div>

           


            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Wide range of Painting Services</h1>

                <div className="SpaceCount_slidediv mt-3">
                    {PaintingServicesData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="SpaceCount_div_slide">
                                {/* <img src={item.imgSrc} alt={`img-${item.id}`} /> */}
                                <h4>{item.heading}</h4>
                                <p>{item.textdetail}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

            <Box className='Boost_about_div text-start px-5'>
                <h1>Best Exterior & Interior Painting Services in Kuwait</h1>
                <p className="pb-1 fs-5">Whether it’s for a home or a commercial establishment, a fresh coat of paint can do wonders for enhancing the appearance of your space, and even make it look like it has been newly renovated. But unless done by the experts, a bad paint job can actually make things worse — blotches of paint can get on other surfaces, walls may have uneven layers of paint, and the shades may not be uniform on all surfaces. In order to achieve the look you desire, you want only the best painters in Kuwait— which means choosing Boost Empire General Trading & Contracting.</p>
                
                <h1>Expert and Professional Kuwait Painters at your Service</h1>
                <p className="pb-1 fs-5">In this industry, the experience is everything – and that is why we have made sure to only enlist professional and experienced painters to join our company. By choosing the right people for the job and equipping them with the essential tools, our company is able to deliver the best results to all our clients. With Boost Empire General Trading & Contracting, you can expect painting services in Kuwait that are efficient, fast, and timely service that is done right the first time.</p>                
            </Box>

            <Box className='Boost_about_div px-5'>
                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo1} alt="img" />
                        <img className=" bg-black h-auto mt-3" src={photo2} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <h6>We are one of the Leading Painting Contractor in Kuwait</h6>
                        <p>For a reliable and efficient painting service, look no further than Boost Empire General Trading & Contracting. As the number one painting company in Kuwait, we offer a comprehensive suite of painting services. We have the expertise to service apartments and villas, as well as commercial establishments, including retail stores, business offices, malls and boutiques, hotels and restaurants, and more.</p>
                        <p>As one of the top painting companies in the city, we provide a meticulous and impeccable service. Prior to starting any project, our professional painters make sure the area is properly prepared to avoid damaging or staining other surfaces. Though our painters deliver a thorough service, we make sure our painting company completes the job on time and without mistakes. We always strive to listen to the specific requirements of clients and companies that hire us – be it about the colour, style, or design of the paint job you want.</p>
                        
                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>

                </Box>
            </Box>


            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Contact us for Hassle free Painting works</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY<MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default PaintingServices