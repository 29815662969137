import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/GypsumFloorwork1.jpg'
import photo3 from '../../assest/BootsEmpire/GypsumFloorworkBanner.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import GypsumFloorTilesWorkData from "../../data/BootsEmpire/GypsumFloorTilesWorkData"
import GypsumFloorTilesWorkImageData from "../../data/BootsEmpire/GypsumFloorTilesWorkImageData"


const GypsumFloorTilesWork = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Gypsum & Floor Tiles Work</h1>
                </div>
            </Box>

            <Box className='Boost_about_div px-5'>
                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo1} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <h6>Professional Team of Gympsum & Tiling Works</h6>
                        <p>With 7+ years of experience in gympsum and tiling services and supplies, our team of  experts are here to help you with all your tiling and gympsum needs. Whether it is a complicated issue or one that just needs a quick fix, we’ll help you figure it out and fix it at the most affordable cost.</p>
                    
                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>

                </Box>
            </Box>




            <Box className='Boost_about_div text-start px-5'>
                <p className=" fs-5"> If you are looking for a beautifully designed ceiling, wall, or roof of gypsum board, then you are at the right place. We create the best gypsum boards with the design that you want. Gypsum board walls and ceilings could turn your home and office into a classy place. We are here to design the most challenging gypsum board designs that can change your interiors’ look.</p>
                <p className=" fs-5"> We have a team of the best gypsum board ceiling, walls, and roof designers who turn your dreams into reality. They are highly experienced and can turn your ordinary interior of home or office into an extraordinary and classy one. If you are planning to renovate the interior of your house, then give us a call. Our designers will approach you. All you have to do is to call us.</p>
                <p className=" fs-5"> We provide the best high-quality manufactured Gypsum boards with high-quality materials. If you want a durable ceiling, floor, or roof lined with gypsum boards, then you can choose us without worrying about the costs. We are here to turn your ideal house dreams come true and provide you with the best services in the town.</p>
            </Box>

            <div className="allpackages_div">
                <h1>How can we help you?</h1>
                <div className="packages_slide">
                    {GypsumFloorTilesWorkData?.map((item, index) => (
                        <div key={item.id} className="packages_life_Slider">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                            <h4>{item.heading}</h4>
                            <p className="px-2 py-3">{item.textInfo}</p>
                        </div>
                    ))}
                </div>
            </div>

        

            <div className=" bg-black">
                <GalleryPhotos GalleryData={GypsumFloorTilesWorkImageData} title={""} />
            </div>

            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Call us for tiling & gypsum work</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY<MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default GypsumFloorTilesWork