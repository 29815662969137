
import comingsoon1 from '../../assest/BootsEmpire/PlumbingServicesImg1.jpg'
import comingsoon2 from '../../assest/BootsEmpire/PlumbingServicesImg2.jpg'
import comingsoon3 from '../../assest/BootsEmpire/PlumbingServicesImg3.jpg'
import comingsoon4 from '../../assest/BootsEmpire/PlumbingServicesImg4.jpeg'
import comingsoon5 from '../../assest/BootsEmpire/PlumbingServicesImg5.jpg'



const PlumbingworkImageData = [
    {
        id: 1,
        src: comingsoon3,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon1,
    }, {
        id: 4,
        src: comingsoon4,
    }
    , {
        id: 5,
        src: comingsoon5,
    }
   
   
    
];
export default PlumbingworkImageData;