


import people1 from '../../assest/BootsEmpire/BedBugControl​Img.jpg'
import people2 from '../../assest/BootsEmpire/Cockroaches Treatment​ img.webp'
import people3 from '../../assest/BootsEmpire/Termite Control​ img.webp'
import people4 from '../../assest/BootsEmpire/Rodent Control img.jpg'
import people5 from '../../assest/BootsEmpire/PestControlServicesImg6.jpg'
import people6 from '../../assest/BootsEmpire/Spiders Pest Control img.webp'



const PestControlServicesData = [
    {
        id: 1,
        heading:'Bed Bug Control​',
        textInfo:'If you have presence of bed bugs in your space, the only thing on your mind will be eliminating them as quickly as conceivable by availing quality bed bugs control services & treatment. At Boost Empire General Trading & Contracting, we can provide a thorough examination of the affected area, recognizing the degree of your bedbug problem and offering you the finest choice from the several bed bug control services we have on offer.',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Cockroaches Treatment​',
        textInfo:'Cockroaches can lead to a war like setting in your space. Boost Empire General Trading & Contracting provides specialized cockroach treatments to win the war against them. We offer cockroach treatment solutions to prevent the cockroaches from disrupting your life. Our cockroach treatment will kill the cockroaches & stop the cockroaches to come into your residential or office space.',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Termite Control​',
        textInfo:'Our complete termite control services combine a pack of termite management techniques into one all-inclusive treatment plan, protecting your entire space from all termite species. Our innovative, effective termite control services are based on advanced technology so it stops the termites to eat the wood and other vital accessories.',
        imgSrc: people3,
    },
    {
        id: 4,
        heading:'Rodent Control',
        textInfo:'Our rodent control services emphasize on laying a trap and removing the prevailing rats/rodents and averting new rodents and mice from re-entering your space. The rodents are also prone to damage objects available in homes, shops, offices that includes computers, furniture, electrical equipment, wires and various hardware objects, etc. ',
        imgSrc: people4,
    },
    {
        id: 5,
        heading:'Mosquitoes/Flies Pest Control​',
        textInfo:'The Mosquito/ Flies Pest Control Treatment helps everyone to curb the growth rate of files & mosquitoes as well as keep them distant from the residential or business spaces. Our mosquito/flies pest control services also ensure that there is no recurrence of such problem in future.',
        imgSrc: people5,
    },
    {
        id: 6,
        heading:'Spiders Pest Control',
        textInfo:'Getting rid of spiders is a common demand from people but finding a safe and finest spider control pest service is important. Our spider pest control treatment is eco-friendly with assured fast removal and creating a shield around your space whether it is commercial or residential.',
        imgSrc: people6,
    },
   
];
export default PestControlServicesData