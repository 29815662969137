

import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/KitchenDustCleaningBannerImg.jpg'
import photo2 from '../../assest/BootsEmpire/KitchenDustCleaningImg1.png'
import photo3 from '../../assest/BootsEmpire/KitchenDustCleaningImg6.jpg'
import photo4 from '../../assest/BootsEmpire/KitchenDustCleaningImg3.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import KitchenDuctCleaningImageData from "../../data/BootsEmpire/KitchenDuctCleaningImageData"




const KitchenDuctCleaning = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo1} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Kitchen Duct Cleaning</h1>
                </div>
            </Box>

            <Box className='Boost_about_div text-start'>
                <h1 className=" fw-bold px-5 pt-5"> Kitchen Duct Cleaning Service in Kuwait</h1>
                <p className="px-5 fs-5">Consistent maintenance of a kitchen exhaust system is one of the main shields to counter fire hazards. It aids in keeping these systems functioning at their best and vacate smoke and grease out of the space more effectively.</p>
                <p className="px-5 fs-5 pb-5">At Boost Empire General Trading & Contracting, our established kitchen duct cleaning methods remove the grease and combustible residues on interior surfaces of the hood, filters and exhaust fans.</p>

                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo4} alt="img" />
                        <img className=" bg-black h-auto mt-4" src={photo3} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <h1 className="">How our commercial air duct cleaning services can support your business?</h1>
                        <p className="pb-1 fs-5">With our air duct cleaning solutions, we can aid you to:</p>
                        <ul className="pt-3 fs-5">
                            <li>Enhance indoor air quality</li>
                            <li>Lessen the possibility for mould growth</li>
                            <li>Extend the life of HVAC equipment</li>
                            <li>Eliminate unpleasant odours</li>
                            <li>Offer a clean, healthier environment for allergy sufferers</li>
                            <li>Save your money on potential energy expenditures</li>
                        </ul>
                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US <MdChevronRight className=" ms-3 fs-4" /></Button></Link>

                    </div>
                </Box>
            </Box>

            


            <Box className='Boost_about_div text-start px-5'>
                <h1>We Keep Your Business Open</h1>
                <p className="pb-1 fs-5">Remember, the key benefit of kitchen duct cleaning is that it enables your business to remain open. Without regular cleanings, your kitchen would gradually become in violation of local fire and safety standards. Getting your kitchen duct cleaning done from Boost Empire General Trading & Contracting in Kuwait is particularly a smart choice since we offer convenient proof-of-service stickers that make record-keeping easy.</p>
            </Box>


            <Box className='Boost_about_div text-start px-5'>
                <h1>Laundry Hood Cleaning</h1>
                <p className="pb-1 fs-5">Your laundry hood is at risk of fire threat if garment fibres, dust and lint are remained unchecked in the exhaust system. Boost Empire General Trading & Contracting is a reliable Kuwait-based laundry hood cleaning company with years of expertise. We have been consistently providing laundry hood cleaning services for hospitals, launderettes, care homes and hotels across Kuwait.</p>
                <p className="pb-1 fs-5">We believe every space is unique. Hence, we perform an on-site inspection to endow with a personalized laundry duct cleaning solution and set up a cleaning schedule that fulfils your site’s precise requirements. For example, we might suggest a regular laundry duct cleaning schedule once every 3 to 6 months depending on the usage.</p>
                <p className="pb-1 fs-5">Our cleaning expert will execute an in-depth check of your laundry duct to learn whether the filters are in place and safe, and we will recognize the level of trash in the duct work.</p>
                <p className="pb-1 fs-5">Moreover, we carefully clear lint from all filters in the dryers. The fan unit and ductwork would then be cleaned as per the industry standard. Any lint which spills out would be cleaned up. We then leave your laundry hood system totally hygienic and dust free. All work is done at a time slot that meets your schedule.</p>
            </Box>


            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <h1>Prime Benefits Of Kitchen Duct Cleaning</h1>
                        <ul className=" fs-5 pt-3">
                            <li>Increase Fire Safety</li>
                            <li>Get Insurance Compliance In The Event Of Fire Outbreak</li>
                            <li>Increase Extract Fan Efficiency</li>
                            <li>Lower electrical bills</li>
                            <li>Increased Levels of Hygiene</li>
                        </ul>

                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>

                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo2} alt="img" />
                    </div>
                </Box>
            </Box>



            <Box className='Boost_about_div text-start px-5'>
                <h1>Air Duct Cleaning</h1>
                <p className="pb-1 fs-5">Air ducts are a dock for dirt and debris that can accumulate over time. As the dust endures to grow in these ducts, the air quality of your home or office can turn worse over time. Bacteria, Mold, Mildew and even Fungi could begin to grow in these ducts which can lead to illness. At Boost Empire General Trading & Contracting, we offer professional air duct cleaning services that will make sure that your AC ducts are as clean as conceivable, while making certain they remain efficient.</p>
                <p className="pb-1 fs-5">Our AC duct cleaning process is best suited to office buildings, hotels, schools, nursing homes, and hospitals, in addition to other residential, industrial, commercial, and institutional facilities.</p>
            </Box>


            <Box className='Boost_about_div text-start px-5'>
                <h1>Eliminate Dust & Dirt With Air Duct Cleaning Right Away</h1>
                <p className="pb-1 fs-5">Another benefit to air duct cleaning most people don’t understand is that it will save up your money. As the dirt and debris gathers in these ducts, it means your air conditioner will have to function more in order to operate. This means it will cost you more to uphold the temperature of your space and that means more money is wasted on utilities, instead of the other items that you need.</p>
                <p className="pb-1 fs-5">With our professional air duct cleaning in Kuwait, you can aid in improving the health of your family or employees, while at the same time saving up your money. This is vital to keep in mind as you evaluate the pros and cons of air duct cleaning services.</p>
                <p className="pb-1 fs-5">Also, when air ducts get dirty and mouldy owing to the accumulation of dirt, it endorses the development of bacteria and allergens. These bacteria and allergens lead to several health concerns of which most common is breathing illness such as asthma, common colds and coughs. In few cases, it can even lead to much serious lung concerns.</p>
            </Box>



            <Box className='Boost_about_div text-start px-5'>
                <h1>How Frequent Should You Clean Your Air Ducts?</h1>
                <p className="pb-1 fs-5">Consistent servicing of air ducts must be done to avert fires and illnesses from low hygiene in ductwork. We suggest all HVAC systems and extractors ought to be examined for cleanliness every year.</p>
                <p className="pb-1 fs-5">In terms of duct cleaning, we would recommend that you properly clean your air ducts in every 2-3 years. This will make sure that the fresh air is being transferred through the building instead of unhealthy dust, mould and bacteria. </p>
                <p className="pb-1 fs-5">As one of the top duct cleaning company in Kuwait, we ensure our teams deliver quick, wide-ranging solutions for property managers & owners, across an extensive range of public and commercial sectors, supporting them to address, accomplish and maintain your current safety and compliance.</p>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <h1>Our duct cleaning process</h1>
                <p className="pb-1 fs-5">Step 1: Our experts fix a powerful vacuum to the air duct near the furnace.</p>
                <p className="pb-1 fs-5">Step 2: Top commercial-grade equipment is then utilized to free the contaminants and wreckages present in the air duct system.</p>
                <p className="pb-1 fs-5">Step 3: Contaminants are extracted from the duct system and via the filtration system, giving back clean air right into the facility.</p>
                <p className="pb-1 fs-5">Step 4: After the duct cleaning is done, all access holes are re-sealed, ensuring that the duct system is now available in new, clean condition.</p>
            </Box>
            


            <Box className='Boost_about_div text-start px-5'>
                <h1>Better Odour Control</h1>
                <p className="pb-1 fs-5">Being a leading duct cleaning company in Kuwait, we have trained our staffs to keep your kitchen spaces clean, sanitised and health regulatory compliant. We have extremely concentrated and low-odour commercial oven cleaner as well as degreaser cleaners, anti-bacterial sanitizer & safe grease trap cleaners. We’ll utilize the kitchen degreaser to spray out clean and degrease all the interior and exterior part of ventilation ducts and do cleansing of stainless-steel surfaces to eliminate the oils, carbon deposits and other undesirable filth. Our kitchen duct cleaning solutions will also make use of filter cleaner to deep soak and make sure they’re meticulously kept hygienic and in working order.</p>
            </Box>



            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us?</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>

            <div className=" bg-black">
                <GalleryPhotos GalleryData={KitchenDuctCleaningImageData} title={""} />
            </div>


            <Box className='Boost_about_div text-start px-5'>
                <p className="pb-1 fs-5">Our professional scope of duct cleaning services comprises of cleaning up key components and duct-work carefully in the kitchen exhaust system.</p>
                <p className="pb-1 fs-5">Our duct cleaning services are here to aid you to have proper maintenance and to avert fires. Owing to continuous use for a long phase of time, grease and other flammable materials keep collecting inside the ductwork, that eventually compromises your safety. By cleaning the whole system, we are able to hunt for probable system blockage, and visually confirm that the louver and dampers are working properly.</p>
                <p className="pb-1 fs-5">Based on the kind of cuisine you serve, the cooking fuels you utilize and how occupied your restaurant is, we more or less recommend that a cleaning schedule be fixed in every 6 months at a minimum.</p>
                <p className="pb-1 fs-5">Upon completion of our kitchen duct cleaning service, you will receive a validation sticker specifying the cleaning of the system, a cleaning report together with our comments and a certificate. While cleaning, we make sure that we do not upset the functioning of your workplace. As soon as we have meticulously cleaned the ducts, we share a before-after evidence by means of photographs.</p>
                <p className="pb-1 fs-5">We understand that a commercial or restaurant kitchen works all night and days, thus we always ensure that our duct cleaning service is flexible and is according to your schedule. </p>
            </Box>


            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Breathe Easy With Duct Cleaning</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default KitchenDuctCleaning