


import people1 from '../../assest/BootsEmpire/secure.svg'

const OurPestControlProcessData = [
    {
        id: 1,
        heading: "Survey",
        textdetail: "Our pest controller will study your residential or commercial property (together with hard-to-reach spaces) to understand the kind of pest and intensity of infestation. This will help him select the fastest and most effective pest treatment plan.",
        imgSrc: people1,
    },

    {
        id: 2,
        heading: "Treatment",
        textdetail: "With cutting-edge residential and commercial pest control services to manage pest activity in Kuwait, we can eliminate every last bug and rodent hidden at your premises. We deliver same-day, emergency pest treatments based on the pest type and stage of infestation.",
        imgSrc: people1,
    },
    {
        id: 3,
        heading: "Observation",
        textdetail: "Monitoring is a significant part of our pest control services. Our assured service comprises of up to two additional sessions (1 major visit and 2 follow-ups) upon demand, within a phase of 3 months after the preliminary pest treatment.",
        imgSrc: people1,
    },
    {
        id: 4,
        heading: "Proofing & Prevention",
        textdetail: "Our pest control service will block any open entry points as a concluding touch so as to avert additional intrusion. We will also provide you with a written report for the pest control service done as well as some valuable tips for future prevention.",
        imgSrc: people1,
    }
];
export default OurPestControlProcessData