
import comingsoon1 from '../../assest/BootsEmpire/Construction.jpg'
import comingsoon2 from '../../assest/BootsEmpire/AC Services.jpg'
import comingsoon3 from '../../assest/BootsEmpire/Building Maintenance.jpg'
import comingsoon4 from '../../assest/BootsEmpire/1520208937838.jpeg'
import comingsoon5 from '../../assest/BootsEmpire/Electrical Maintenance.jpg'
import comingsoon6 from '../../assest/BootsEmpire/Mechanicalwork.png'
import comingsoon7 from '../../assest/BootsEmpire/Simple-Modern.jpg'
import comingsoon8 from '../../assest/BootsEmpire/a98de5d8aa65.jpg'
import comingsoon9 from '../../assest/BootsEmpire/White_Town_Villas_1.jpg'
import comingsoon10 from '../../assest/BootsEmpire/waterproofing-membranes.jpg'
import comingsoon11 from '../../assest/BootsEmpire/White_Town_Villas_1.jpg'
import comingsoon12 from '../../assest/BootsEmpire/plumbing-service.webp'
import comingsoon13 from '../../assest/BootsEmpire/modern-home-exterior.jpg'
import comingsoon14 from '../../assest/BootsEmpire/industrialpipe.jpg'
import comingsoon15 from '../../assest/BootsEmpire/front-door-08.jpg'




const GallerData = [
    {
        id: 1,
        src: comingsoon1,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon3,
    }, {
        id: 4,
        src: comingsoon4,
    }, {
        id: 5,
        src: comingsoon5,
    }, {
        id: 6,
        src:comingsoon6,
    },
     {
        id: 7,
        src:comingsoon7,
    }, {
        id: 8,
        src:comingsoon8,
    }, {
        id: 9,
        src:comingsoon9,
    },
     {
        id: 10,
        src:comingsoon10,
    },
     {
        id: 11,
        src:comingsoon11,
    },
     {
        id: 12,
        src:comingsoon12,
    },
     {
        id: 13,
        src:comingsoon13,
    },
     {
        id: 14,
        src:comingsoon14,
    },
     {
        id: 15,
        src:comingsoon15,
    },
    //  {
    //     id: 16,
    //     src:comingsoon16,
    // },
    //  {
    //     id: 17,
    //     src:comingsoon17,
    // },
    //  {
    //     id: 18,
    //     src:comingsoon18,
    // },
    //  {
    //     id: 19,
    //     src:comingsoon19,
    // },
    //  {
    //     id: 20,
    //     src:comingsoon20,
    // },
    // {
    //     id: 21,
    //     src:comingsoon21,
    // },
    // {
    //     id: 22,
    //     src:comingsoon22,
    // },
    // {
    //     id: 23,
    //     src:comingsoon23,
    // },
    // {
    //     id: 24,
    //     src:comingsoon24,
    // },
    // {
    //     id: 25,
    //     src:comingsoon25,
    // },
    // {
    //     id: 26,
    //     src:comingsoon26,
    // },
    // {
    //     id: 27,
    //     src:comingsoon27,
    // },
    // {
    //     id: 28,
    //     src:comingsoon28,
    // },
    // {
    //     id: 29,
    //     src:comingsoon29,
    // },
    // {
    //     id: 30,
    //     src:comingsoon30,
    // },
    // {
    //     id: 31,
    //     src:comingsoon31,
    // },
    // {
    //     id: 32,
    //     src:comingsoon32,
    // },
    // {
    //     id: 33,
    //     src:comingsoon33,
    // },
    // {
    //     id: 34,
    //     src:comingsoon34,
    // },
    // {
    //     id: 35,
    //     src:comingsoon35,
    // },
    // {
    //     id: 36,
    //     src:comingsoon36,
    // },
    // {
    //     id: 37,
    //     src:comingsoon37,
    // },
    // {
    //     id: 38,
    //     src:comingsoon38,
    // },
    // {
    //     id: 39,
    //     src:comingsoon39,
    // },
    // {
    //     id: 40,
    //     src:comingsoon40,
    // },
    // {
    //     id: 41,
    //     src:comingsoon41,
    // },
    // {
    //     id: 42,
    //     src:comingsoon42,
    // },
    // {
    //     id: 43,
    //     src:comingsoon43,
    // },
    // {
    //     id: 44,
    //     src:comingsoon44,
    // },
    // {
    //     id: 45,
    //     src:comingsoon45,
    // },
    
];
export default GallerData;