
import polygon from '../../assest/BootsEmpire/client_banner1.jpg'
import { Box, Button } from "@mui/material"
import '../../styles/BootsEmpire/ClientStyle.css'
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import BootsEmpireFooter from '../../common/BootsEmpire/Footer/BootsEmpireFooter'
import OurClientData from '../../data/BootsEmpire/OurClientData'




const Client = () => {

    return (
        <>
            <BootsEmpireHeader />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1 style={{ zIndex:-33}}>Cleints</h1>
                </div>
            </Box>

            <Box className='ourdiv_clients'>
                <h1>OUR CLIENTS</h1>
                <div className="ourclients_partner">
                    {OurClientData?.map((item, index) => (
                        <div key={item.id} className="Projects_Cleintstopimg">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                        </div>
                    ))}
                </div>
            </Box>



            <BootsEmpireFooter />

        </>
    )
}

export default Client