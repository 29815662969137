import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/ElectricalMaintenanceImg1.jpg'
import photo3 from '../../assest/BootsEmpire/ElectricalMaintenanceBannerImg.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import mechanicalworkImageData from "../../data/BootsEmpire/mechanicalworkImageData"
import ElectricalServicesData from "../../data/BootsEmpire/ElectricalServicesData"
import ElectricalworkImageData from "../../data/BootsEmpire/ElectricalworkImageData"


const ElectricalMaintenance = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow:'30px 30px 30px 30px black'}}>Electrical Maintenance</h1>
                </div>
            </Box>


            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <p className=" fw-bold" style={{ color: '#2764d6' }}>Electrical works</p>
                        <h6>Electrical Maintenance in Kuwait</h6>
                        <p>As an electrical maintenance services provider, our primary focus would be to ensure the smooth operation, repair, and upkeep of electrical systems in residential, commercial, and industrial settings. This may include conducting inspections, troubleshooting electrical issues, performing routine maintenance tasks, and repairing or replacing faulty components.</p>
                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo1} alt="img" />
                    </div>
                </Box>
            </Box>


            <div className="SpaceCount_div">
                <h1 className=" fw-bold py-3">Electrical Services</h1>
                <div className="SpaceCount_slidediv">
                    {ElectricalServicesData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="SpaceCount_div_slide">
                                {/* <img src={item.imgSrc} alt={`img-${item.id}`} /> */}
                                <h4>{item.heading}</h4>
                                <p>{item.textdetail}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>


            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={ElectricalworkImageData} title={""} />
            </div>

            <Box className='Boost_about_div text-start px-5'>
                <p className="pt-4 pb-1 fs-5">Choose Boost Empire General Trading & Contracting Co. W.L.L for reliable and professional electrical maintenance services in Kuwait. Contact us today to discuss your electrical maintenance needs and schedule a consultation. Trust our expertise to keep your electrical systems running smoothly, efficiently, and safely.</p>
            </Box>

            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Contact for Electrical Maintenance Services</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">Get an Estimate <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default ElectricalMaintenance