

const MechanicalServicesData = [
    {
        id: 1,
        heading: "HVAC Systems",
        textdetail: "We specialize in the design, installation, and maintenance of HVAC (Heating, Ventilation, and Air Conditioning) systems. Whether you need a new HVAC system for your building or require repairs and maintenance for an existing one, our team ensures optimal comfort, energy efficiency, and indoor air quality.. ",
    },

    {
        id: 2,
        heading: "Plumbing",
        textdetail: "Our skilled plumbers and technicians handle all aspects of plumbing installations, including pipes, fixtures, and drainage systems. We ensure proper connections, efficient water distribution, and effective wastewater management for residential, commercial, and industrial properties.. ",
    },
    {
        id: 3,
        heading: "Fire Protection",
        textdetail: "We provide comprehensive fire protection solutions, including fire sprinkler systems, fire suppression systems, and fire alarm systems. Our team ensures that your property meets all fire safety regulations and standards, protecting lives and assets.. ",
    },
    {
        id: 4,
        heading: "Equipments",
        textdetail: "We offer scheduled equipment maintenance services to keep your mechanical systems running smoothly and efficiently. Our preventive maintenance programs help minimize downtime, extend equipment lifespan, and prevent costly repairs.. ",
    },

];
export default MechanicalServicesData