
import people1 from '../../assest/BootsEmpire/badge.png'
import people2 from '../../assest/BootsEmpire/worker.png'
import people3 from '../../assest/BootsEmpire/handshake.png'
import people4 from '../../assest/BootsEmpire/value-proposition.png'




const SustainabilityData = [
    {
        id: 1,
        heading: "QUALITY",
        textdetail: "We have Providing assured quality service to clients every time..",
        // linktag:"Seehot",
        imgSrc: people1,
    },

    {
        id: 2,
        heading: "SAFETY",
        textdetail: "High standards of environment and occupational health safety.",
        // linktag:"Checkomains",
        imgSrc: people2,
    },
    {
        id: 3,
        heading: "COMMITMENT",
        textdetail: "Perfection with passion and responsibilities.",
        // linktag:'Browsemains',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: "VALUES",
        textdetail: "Team work, integrity and commitment.",
        // linktag:'Browsemains',
        imgSrc: people4,
    }
];
export default SustainabilityData