import React, { useState } from 'react'
import Logo from "../../../assest/BootsEmpire/BootsEmpireLogo.jpeg";
import "./BootsEmpireHeaderStyle.css";
import { Box, Button, Drawer } from '@mui/material';
import { Link } from 'react-router-dom';
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { FaSquareTwitter, FaSquareInstagram, FaSquareFacebook } from "react-icons/fa6";
import SideBar from '../sidebar/SideBar';
import { MdOutlineMenu } from "react-icons/md";


const BootsEmpireHeader = () => {

    const [openSide, setOpenSide] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpenSide(newOpen);
    };

    return (
        <>
            <Box className="top_contactdiv">
                <div>
                    <ul>
                        <li>
                            <Link to="tel:+965 502 86435">
                                <IoCall className='fs5' /> +965 502 86435
                            </Link>
                        </li>
                        <li>
                            <Link to="mailto:info@boostempires.com">
                                <MdOutlineEmail className='fs5' /> info@boostempires.com
                            </Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            <Link to="" target="_blank">
                                <BsLinkedin className='fs3' />
                            </Link>
                        </li>
                        <li>
                            <Link to="" target="_blank">
                                <FaSquareFacebook className='fs3' />
                            </Link>
                        </li>
                        <li>
                            <Link to="" target="_blank">
                                <FaSquareTwitter className='fs3' />
                            </Link>
                        </li>
                        <li>
                            <Link to="" target="_blank">
                                <FaSquareInstagram className='fs3' />
                            </Link>
                        </li>


                    </ul>
                </div>
            </Box>


            <Box className="abarisDan_Header">
                <div className='tanishkadiv_mobile'>
                    <Button onClick={toggleDrawer(true)}><MdOutlineMenu className=' fs-1textwhite' /></Button>
                    <Drawer open={openSide} onClose={toggleDrawer(false)}>
                        <SideBar setOpenSide={setOpenSide} />
                    </Drawer>
                </div>
                <div className='logo_divbox'>
                    <Link className='' to="/">
                        <img className="logo" src={Logo} alt='Logo' />
                    </Link>

                    <p className='compnyName'>Boost Empire  <span> General Trading & Contracting Co.</span></p>
                </div>


                <Box className='abarisDan_rightUL'>
                    <ul className="abarisDan_liauto">
                        <li>
                            <Link to="/"> HOME </Link>
                        </li>

                        <li>
                            <Link to="/about_us"> ABOUT US </Link>
                        </li>

                        <li className='open_list'>
                            <Link> MEP CONTRACTING</Link>
                            <Box className='hidegroup'>
                                <div className='Ourboots_maindiv'>
                                    <h6><Link to={'/contracting_work'}> Contracting Work</Link></h6>
                                    <h6><Link to={'/mechanical_works'}> Mechanical Works</Link></h6>
                                    <h6><Link to={'/electrical_maintenance'}> Electrical Maintenance</Link></h6>
                                    <h6><Link to={'/plumbing_services'}> Plumbing Services</Link></h6>
                                    <h6><Link to={'/building_maintenance'}> Building Maintenance</Link></h6>
                                    <h6><Link to={'/AC_services'}> AC Services</Link></h6>
                                </div>
                            </Box>
                        </li>

                        <li className='open_list'>
                            <Link> CIVIL WORK </Link>
                            <Box className='hidegroup'>
                                <div className='Ourboots_maindiv'>
                                    <h6><Link to={'/waterproofing_solutions'}> Waterproofing Solutions</Link></h6>
                                    <h6><Link to={'/painting_services'}> Painting Services</Link></h6>
                                    <h6><Link to={'/gypsum_floor_tiles_work'}> Gypsum & Floor tiles work</Link></h6>
                                    <h6><Link to={'/interior'}> Commercial & Residential Interiors</Link></h6>
                                </div>
                            </Box>
                        </li>


                        <li className='open_list'>
                            <Link> CLEANING SERVICES </Link>
                            <Box className='hidegroup'>
                                <div className='Ourboots_maindiv'>
                                    <h6><Link to={'/grease_trap_cleaning'}> Grease Trap Cleaning</Link></h6>
                                    <h6><Link to={'/deep_cleaning_services'}> Deep Cleaning Services</Link></h6>
                                    <h6><Link to={'/sofa_carpet_cleaning'}> Sofa & Carpet Cleaning</Link></h6>
                                    <h6><Link to={'/pest_control_services'}> Pest Control Services</Link></h6>
                                    <h6><Link to={'/water_tank_cleaning'}> Water Tank Cleaning</Link></h6>
                                    <h6><Link to={'/car_wash_services'}> Car Wash Services</Link></h6>
                                    <h6><Link to={'/kitchen_duct_cleaning'}> Kitchen Duct Cleaning</Link></h6>
                                    <h6><Link to={'/post_construction_cleaning'}>Post Construction Cleaning</Link></h6>
                                </div>
                            </Box>
                        </li>

                        <li>
                            <Link to="/interior"> INTERIOR </Link>
                        </li>

                        <li>
                            <Link to="/trading"> TRADING </Link>
                        </li>

                        <li>
                            <Link to="/client"> CLIENTS </Link>
                        </li>

                        <li>
                            <Link to="/contact_us"> CONTACT US </Link>
                        </li>


                    </ul>
                </Box>
            </Box>
        </>
    )
}

export default BootsEmpireHeader