const FaqData = [
    {
        id: 1,
        title: ' What is Boost Empire General Trading & Contracting?',
        details:'Boost Empire General Trading & Contracting is a MEP Agency based out of Cannaught Place, New Delhi India, Established in 1995, Recognized by all MEPism Apex bodies, affiliated with Department of MEPism, Government of India. Boost Empire General Trading & Contracting is an authorized seller of Premium chain hotels such as Taj, Oberois, ITC, Starwood, Hilton, Marriott, Lemon Tree and many more. We cater to all needs of the travelers like 3,4 or 5 Star Hotels, private cabs, Coach travel, English Speaking Drivers , Guides as well as niche demands like Senior Citizen MEPs, Women only MEPs ,Wildlife Expeditions , Photography MEPs , Customized Honeymoon MEPs, etc.',
    },
    {
        id: 2,
        title: " Why should I trust you?",
        details:'Boost Empire General Trading & Contracting has been operating since 1995, The guest feedback for Boost Empire General Trading & Contracting is excellent (Refer to the testimonials), On securing a tour with us, We provide the guest with a payment flexibility where only a token advance is to be paid to process the reservation, balance payment can be remitted after we provide the confirmations from the hotel / hotels.',    
    },
    {
        id: 3,
        title: "How much would I pay upfront?",
        details:' Based on the discussion with your MEP Counselor, you have to pay 10% - 25% of your Total Trip amount to process the reservation. The balance amount can be paid after receiving the MEP vouchers.',    
    },
    {
        id: 4,
        title: " What are the supported modes of payment you accept?",
        details:' You can pay via Bank Transfers, Cheques or in cash. We accept all Visa, MasterCard and American Express credit cards and most major debit cards which are subject to 2.5% processing fee.',    
    },
    {
        id: 5,
        title: "What is your cancellation policy?",
        details:"Since we customize packages as per your demands, the cancellation policy varies across hotels and services. However, you're informed about the detailed cancellation policy before you make any payments",    
    },
    {
        id: 6,
        title: "What if I face any issues during the MEP?",
        details:' There would be a dedicated MEP Counselor assigned exclusively to you, who would be in touch with you from the time when the query was initiated till the time the tour ends, In case of any issues faced by you, a solution would be immediately offered, still unsatisfied you can escalate the call to our Director, Mr. Gaurav Chawla - Mobile No. 98101-00293. The Hotels, Transport and the services used by us are tried and tested for over 25 years.',    
    },
    // {
    //     id: 7,
    //     title: "Howroup",
    //     details:'ffff',    
    // },
    // {
    //     id: 8,
    //     title: "Howhave",
    //     details:'ffff',    
    // },
    // {
    //     id: 9,
    //     title: "Hownumber",
    //     details:'ffff',    
    // },
    // {
    //     id: 10,
    //     title: "Whatollow",
    //     details:'ffff',    
    // }
];

export default FaqData