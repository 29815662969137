
import comingsoon1 from '../../assest/BootsEmpire/BuildingMaintenanceImg1.jpg'
import comingsoon2 from '../../assest/BootsEmpire/BuildingMaintenanceImg2.jpg'
import comingsoon3 from '../../assest/BootsEmpire/BuildingMaintenanceImg3.webp'
import comingsoon4 from '../../assest/BootsEmpire/BuildingMaintenanceImg4.jpg'
import comingsoon5 from '../../assest/BootsEmpire/BuildingMaintenanceImg5.webp'
import comingsoon6 from '../../assest/BootsEmpire/BuildingMaintenanceImg6.jpg'



const BuildingMaintenanceWorkImageData = [
    {
        id: 1,
        src: comingsoon3,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon1,
    }, {
        id: 4,
        src: comingsoon4,
    }
    , {
        id: 5,
        src: comingsoon5,
    }
    , {
        id: 6,
        src: comingsoon6,
    }
    
];
export default BuildingMaintenanceWorkImageData;