

import comingsoon1 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-041.jpg'
import comingsoon2 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-042.jpg'
import comingsoon3 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-043.jpg'
import comingsoon4 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-044.jpg'
import comingsoon5 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-087.jpg'
import comingsoon6 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-046.jpg'
import comingsoon7 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-047.jpg'
import comingsoon8 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-048.jpg'
import comingsoon9 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-049.jpg'
import comingsoon10 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-050.jpg'
import comingsoon11 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-051.jpg'
import comingsoon12 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-052.jpg'
import comingsoon13 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-053.jpg'
import comingsoon14 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-054.jpg'
import comingsoon15 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-055.jpg'
import comingsoon16 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-056.jpg'
import comingsoon17 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-057.jpg'
import comingsoon18 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-058.jpg'
import comingsoon19 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-059.jpg'
import comingsoon20 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-060.jpg'
import comingsoon21 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-061.jpg'
import comingsoon22 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-062.jpg'
import comingsoon23 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-063.jpg'
import comingsoon24 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-064.jpg'
import comingsoon25 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-065.jpg'
import comingsoon26 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-066.jpg'
import comingsoon27 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-067.jpg'
import comingsoon28 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-068.jpg'
import comingsoon29 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-069.jpg'
import comingsoon30 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-070.jpg'
import comingsoon31 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-071.jpg'
import comingsoon32 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-072.jpg'
import comingsoon33 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-073.jpg'
import comingsoon34 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-074.jpg'
import comingsoon35 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-075.jpg'
import comingsoon36 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-076.jpg'
import comingsoon37 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-077.jpg'
import comingsoon38 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-079.jpg'
import comingsoon39 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-082.jpg'
import comingsoon40 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-083.jpg'
import comingsoon41 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-085.jpg'
import comingsoon42 from '../../assest/BootsEmpire/Constructa_img/Constructa-image-086.jpg'





const CommercialResidentialInteriorImagesData = [
    {
        id: 1,
        src: comingsoon3,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon1,
    }, {
        id: 4,
        src: comingsoon4,
    }
    , {
        id: 5,
        src: comingsoon5,
    }
    , {
        id: 6,
        src: comingsoon6,
    }
    , {
        id: 7,
        src: comingsoon7,
    }
    , {
        id: 8,
        src: comingsoon8,
    }
    , {
        id: 9,
        src: comingsoon9,
    }
    , {
        id: 10,
        src: comingsoon10,
    }
    , {
        id: 11,
        src: comingsoon11,
    }
    , {
        id: 12,
        src: comingsoon12,
    }
    , {
        id: 13,
        src: comingsoon13,
    }
    , {
        id: 14,
        src: comingsoon14,
    }
    , {
        id: 15,
        src: comingsoon15,
    }
    , {
        id: 16,
        src: comingsoon16,
    }
    , {
        id: 17,
        src: comingsoon17,
    }
    , {
        id: 18,
        src: comingsoon18,
    }
    , {
        id: 19,
        src: comingsoon19,
    }
    , {
        id: 20,
        src: comingsoon20,
    }
    , {
        id: 21,
        src: comingsoon21,
    }
    , {
        id: 22,
        src: comingsoon22,
    }
    , {
        id: 23,
        src: comingsoon23,
    }
    , {
        id: 24,
        src: comingsoon24,
    }
    , {
        id: 25,
        src: comingsoon25,
    }
    , {
        id: 26,
        src: comingsoon26,
    }
    , {
        id: 27,
        src: comingsoon27,
    }
    , {
        id: 28,
        src: comingsoon28,
    }
    , {
        id: 29,
        src: comingsoon29,
    }
    , {
        id: 30,
        src: comingsoon30,
    }
    , {
        id: 31,
        src: comingsoon31,
    }
    , {
        id: 32,
        src: comingsoon32,
    }
    , {
        id: 33,
        src: comingsoon33,
    }
    , {
        id: 34,
        src: comingsoon34,
    }
    , {
        id: 35,
        src: comingsoon35,
    }
    , {
        id: 36,
        src: comingsoon36,
    }
    , {
        id: 37,
        src: comingsoon37,
    }
    , {
        id: 38,
        src: comingsoon38,
    }
    , {
        id: 39,
        src: comingsoon39,
    }
    , {
        id: 40,
        src: comingsoon40,
    }
    , {
        id: 41,
        src: comingsoon41,
    }
    , {
        id: 42,
        src: comingsoon42,
    }
    
    
];
export default CommercialResidentialInteriorImagesData;