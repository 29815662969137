

import people1 from '../../assest/BootsEmpire/secure.svg'
import people2 from '../../assest/BootsEmpire/secure.svg'
import people3 from '../../assest/BootsEmpire/secure.svg'




const AboutExpertiseData = [
    {
        id: 1,
        heading: "Years Of Experience",
        textdetail: "Received through training, combined with constant striving for improvement.",
        // linktag:"Seehot",
        imgSrc: people1,
    },

    {
        id: 2,
        heading: "Consistency",
        textdetail: "We send the same cleaner every time, whether for weekly or fortnightly service.",
        // linktag:"Checkomains",
        imgSrc: people2,
    },
    {
        id: 3,
        heading: "Friendly Cleaners",
        textdetail: "Fast, reliable and friendly cleaners with experience in ironing and cleaning. Same cleaner every time.",
        // linktag:'Browsemains',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: "Thorough Training",
        textdetail: "We train our cleaners meticulously, passing on years of experience and knowledge.",
        // linktag:'Browsemains',
        imgSrc: people3,
    }
    ,
    {
        id: 5,
        heading: "Affordable Prices",
        textdetail: "Affordable prices with included agency fee and no hidden extra charges.",
        // linktag:'Browsemains',
        imgSrc: people3,
    }
    ,
    {
        id: 6,
        heading: "Fully Insured",
        textdetail: "Our cleaners are fully insured and tested, so you can sit and relax every time.",
        // linktag:'Browsemains',
        imgSrc: people3,
    }
];
export default AboutExpertiseData