

import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/sofaCleaningImg1.jpg'
import photo4 from '../../assest/BootsEmpire/FurnituresCleaningImg1.jpg'
import photo2 from '../../assest/BootsEmpire/CarpetCleaningImg3.jpg'
import photo3 from '../../assest/BootsEmpire/DeepCleaningServicesBanner.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import ElectricalServicesData from "../../data/BootsEmpire/ElectricalServicesData"
import ElectricalworkImageData from "../../data/BootsEmpire/ElectricalworkImageData"
import CleaningServicesData from "../../data/BootsEmpire/CleaningServicesData"
import DeepCleaningServicesImageData from "../../data/BootsEmpire/DeepCleaningServicesImageData"
import SofaCleaningServicesData from "../../data/BootsEmpire/SofaCleaningServicesData"
import photo11 from '../../assest/BootsEmpire/sofa-cleaning-before.jpeg'
import photo22 from '../../assest/BootsEmpire/sofa-cleaning-after.jpeg'
import SofaCarpetCleaningServicesImageData from "../../data/BootsEmpire/SofaCarpetCleaningServicesImageData"



const SofaCarpetCleaning = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Sofa & Carpet Cleaning</h1>
                </div>
            </Box>


            <Box className='Boost_about_div'>
                <h1 className=" px-5 text-start fs-2 pb-5">We are professional sofa, carpet & upholstery cleaning company that is here to help you with all of your cleaning needs.</h1>
                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo2} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <h6>Get Your Place Cleaned and Healthy</h6>
                        <p className=" mb-4">We offer the best cleaning services to your home or office. We make sure that the cleaning is done efficiently and safely. Our company has a team of expert cleaners who are experienced in cleaning all types of interiors including carpets, upholstery and window blinds.</p>
                        <ul>
                            <li>Move In/Outs & Deep Cleaning services</li>
                            <li>Recurring discounts</li>
                            <li>Simple flat rate pricing</li>
                            <li>Easy online booking</li>
                        </ul>
                        
                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>

                </Box>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <p className="pt-4 pb-1 fs-5">Our company is committed to providing top-notch carpet and sofa cleaning services in Kuwait. We understand that carpets and sofas are not just pieces of furniture or decor, but an essential part of your home. Daily use can cause wear and tear that leaves them dirty, stained, and unpleasant to look at. Our skilled team uses modern equipment and non-toxic cleaning products to ensure your carpets and sofas receive a deep clean without any harm being inflicted upon them. We undertake regular training programs for our staff to make sure they utilize the latest technology advancements while offering high-quality services at competitive prices. Our main focus is delivering satisfaction in a timely manner, ensuring that you have a clean environment with fresh smelling carpets and sofas every time you use our services. Trust us with your cherished carpets and sofas as we aim to exceed your expectations with our professional touch!</p>
                <div className=" row my-5 pt-5">
                    <div className=" col-lg-6 col-12">
                        <img className=" bg-black w-100" src={photo11} alt="img" />
                    </div>
                    <div className=" col-lg-6 col-12">
                        <img className=" bg-black w-100" src={photo22} alt="img" />
                    </div>

                </div>
            </Box>




            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">
                    <div className="textdiv col-lg-6 col-12">
                        <h6>Boost Empire General Trading & Contracting sofa cleaning service is vital for the following</h6>
                        <ul>
                            <li>Restoring the brand-new look and feel</li>
                            <li>Removing hard spots & stains from food, coffee spills, ink etc.</li>
                            <li>Eliminating dust accumulated inside the sofa cushions over the period</li>
                        </ul>

                        <h6 className=" fs-5">The following health benefits cannot be ignored</h6>
                        <ul>
                            <li>Keeping skin problems and diseases away</li>
                            <li>Protection from respiratory disease</li>
                            <li>Protection from dust allergies</li>
                            <li>Overall health and wellness</li>
                        </ul>


                        <h6 className=" fs-5">We clean</h6>
                        <ul>
                            <li>Residential – Sofa, Arm chairs, Dining chairs, Majlis sofa, Ottoman</li>
                            <li>Commercial – Sofa, Office chairs, Reception & Meeting chairs</li>
                        </ul>


                        <h6 className=" fs-5">Our sofa cleaning process</h6>
                        <ul>
                            <li>Dry vacuuming of sofa to remove dust and other particles</li>
                            <li>Deep cleaning of sofa using shampooing machine</li>
                            <li>Brushing</li>
                            <li>Extraction using extraction machine</li>
                            <li>Spot removal using special stain remover</li>
                            <li>Repeat shampoo process to remove final debris</li>
                        </ul>

                        <h6 className=" fs-5">Time to dry</h6>
                        <ul>
                            <li>Approximate 12</li>
                        </ul>

                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo1} alt="img" />
                        <img className=" bg-black h-auto mt-3" src={photo4} alt="img" />
                    </div>
                </Box>
            </Box>


            <div className="allpackages_div">
                <h1 className=" pt-0">Let Us Work While You Unwind</h1>
                <div className="packages_slide">
                    {SofaCleaningServicesData?.map((item, index) => (
                        <div key={item.id} className="packages_life_Slider">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                            <h4>{item.heading}</h4>
                            {/* <p className="px-2 py-3">{item.textInfo}</p> */}
                        </div>
                    ))}
                </div>
            </div>


            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={SofaCarpetCleaningServicesImageData} title={""} />
            </div>

            <Box className='Boost_about_div text-start px-5'>
                <h1>We're The Dust Busters!</h1>
                <p className="pt-4 pb-1 fs-5">Sofa and Carpet Cleaning Services in Kuwait are offered by experts for any carpet or upholstery clean up. We use the most advanced equipment, chemicals and detergents to remove dirt and stains from your carpets or upholstery.</p>
            </Box>

            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Call us Today to Book an Appointment</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default SofaCarpetCleaning