import people1 from '../../assest/BootsEmpire/Painting Services.webp'
import people2 from '../../assest/BootsEmpire/Gypsum-and-ceiling.webp'
import people3 from '../../assest/BootsEmpire/waterproofing-membranes.jpg'
import people4 from '../../assest/BootsEmpire/Commercial & Residential Interiors.jpg'



const CivilWorkData = [
    {
        id: 1,
        heading:'Painting Services',
        textdetail:'Internal & External Painting Services in Kuwait Discover comprehensive painting solutions in Kuwait that breathe',
        linkTag: 'painting_services',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Gypsum & Floor tiles work',
        textdetail:'Professional Team of Gympsum & Tiling Works With 7+ years of experience in gympsum and',
        linkTag: 'gypsum_floor_tiles_work',
        imgSrc: people2,
    },

    {
        id: 3,
        heading:'Waterproofing Solutions',
        textdetail:'We Provide Waterproofing solutions in Kuwait We offer comprehensive services to protect your home and',
        linkTag: 'waterproofing_solutions',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'Commercial & Residential Interiors',
        textdetail:'Looking for Quality Interior Company in Kuwait? We specialize in providing top-notch interior works that',
        linkTag: 'interior',
        imgSrc: people4,
    }
   
   
];
export default CivilWorkData