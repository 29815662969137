import comingsoon1 from '../../assest/BootsEmpire/AcServicesImg1.jpg'
import comingsoon2 from '../../assest/BootsEmpire/AcServicesImg2.jpg'
import comingsoon3 from '../../assest/BootsEmpire/AcServicesImg3.jpg'
import comingsoon4 from '../../assest/BootsEmpire/AcServicesImg4.jpeg'
import comingsoon5 from '../../assest/BootsEmpire/AcServicesImg5.png'
import comingsoon6 from '../../assest/BootsEmpire/AcServicesImg7.jpg'
import comingsoon7 from '../../assest/BootsEmpire/AcServicesImg6.webp'



const ACServicesImageData = [
    {
        id: 1,
        src: comingsoon3,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon1,
    }, {
        id: 4,
        src: comingsoon4,
    }
    , {
        id: 5,
        src: comingsoon5,
    }
    , {
        id: 6,
        src: comingsoon6,
    }
    , {
        id: 7,
        src: comingsoon7,
    }
   
    
];
export default ACServicesImageData;