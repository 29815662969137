import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

import 'react-pro-sidebar/dist/css/styles.css';
import "./sideBar.css"
import { MdEventNote, MdOutlineQuestionAnswer, MdOutlineConstruction, MdDisplaySettings } from "react-icons/md";
import { GiIndiaGate, GiWorld } from "react-icons/gi";
import { Link } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { BiMessageSquareDetail } from "react-icons/bi";
import { GiSkills } from "react-icons/gi";
import { CgWebsite } from "react-icons/cg";
import { IoMdPhotos } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
import { MdTour } from "react-icons/md";
import { HiOutlineViewGridAdd } from "react-icons/hi";

const SideBar = () => {


  return (
    <>
      <div id="main-div">
        <ProSidebar
          style={{ height: "100%", position: "absolute" }}
        >
          <div className="sidebar-body-div">
            <SidebarContent className="main-div">
              <Menu className="sidebar-content-div"
                iconShape="circle"
              >
                <p className="Menus-p">MENUS</p>

                <MenuItem icon={<GoHome />}> Home <Link to={'/'} /></MenuItem>

                <MenuItem icon={<FaUser />}> About Us <Link to={'/about_us'} /></MenuItem>


                <SubMenu title="MEP CONTRACTING" icon={<HiOutlineViewGridAdd />}>
                  <MenuItem icon={<MdEventNote />}>Contracting Work<Link to="/contracting_work" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Mechanical Works<Link to="/mechanical_works" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Electrical Maintenance<Link to="/electrical_maintenance" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Plumbing Services<Link to="/plumbing_services" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Building Maintenance<Link to="/building_maintenance" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>AC Services<Link to="/AC_services" /></MenuItem>
                </SubMenu>

                <SubMenu title="CIVIL WORK" icon={<MdOutlineConstruction />}>
                  <MenuItem icon={<MdEventNote />}>Waterproofing Solutions<Link to="/waterproofing_solutions" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Painting Services<Link to="/painting_services" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Gypsum & Floor tiles work<Link to="/gypsum_floor_tiles_work" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Commercial & Residential Interiors<Link to="/interior" /></MenuItem>
                </SubMenu>


                <SubMenu title="CLEANING SERVICES" icon={<MdDisplaySettings />}>
                  <MenuItem icon={<MdEventNote />}>Grease Trap Cleaning<Link to="/grease_trap_cleaning" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Deep Cleaning Services<Link to="/deep_cleaning_services" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Sofa & Carpet Cleaning<Link to="/sofa_carpet_cleaning" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Pest Control Services<Link to="/pest_control_services" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Water Tank Cleaning<Link to="/water_tank_cleaning" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Car Wash Services<Link to="/car_wash_services" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Kitchen Duct Cleaning<Link to="/kitchen_duct_cleaning" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Post Construction Cleaning<Link to="/post_construction_cleaning" /></MenuItem>
                </SubMenu>


                <MenuItem icon={<MdTour />}> INTERIOR <Link to={'/interior'} /></MenuItem>

                <MenuItem icon={<MdTour />}> TRADING <Link to={'/trading'} /></MenuItem>

                <MenuItem icon={<GiSkills />}> CLIENTS <Link to={'/client'} /></MenuItem>

                <MenuItem icon={<IoMdPhotos />}>Photo Gallery <Link to={'/photo_gallery'} /></MenuItem>

                <MenuItem icon={<MdOutlineQuestionAnswer />}>FAQs<Link to={'/FAQ'} /></MenuItem>

                <MenuItem icon={<CgWebsite />}>Blogs<Link to={'/blogs'} /></MenuItem>

                <MenuItem icon={<BiMessageSquareDetail />}>Contact Us<Link to={'/Contact_us'} /></MenuItem>


              </Menu>
            </SidebarContent>
          </div>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBar;

