import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo2 from '../../assest/BootsEmpire/BuildingMaintenanceImg1.jpg'
import photo1 from '../../assest/BootsEmpire/BuildingMaintenanceImg5.webp'
import photo3 from '../../assest/BootsEmpire/BuildingMaintenanceBanner.jpg'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import PlumbingworkImageData from "../../data/BootsEmpire/PlumbingworkImageData"
import BuildingMaintenanceServicesData from "../../data/BootsEmpire/BuildingMaintenanceServicesData"
import BuildingMaintenanceWorkImageData from "../../data/BootsEmpire/BuildingMaintenanceWorkImageData"


const BuildingMaintenance = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo3} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Building Maintenance</h1>
                </div>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <Box className='Boost_about_div'>
                    <Box className="Boost_about_div_home row">
                        <div className="textdiv col-lg-6 col-12">
                            <p className=" fw-bold" style={{ color: '#2764d6' }}>Let us maintain your building</p>
                            <h6>Discover Excellence in Building Maintenance Services</h6>
                            <p>Our services encompass various areas of maintenance, including electrical systems, plumbing, HVAC, structural integrity, and general repairs. Our team of skilled and licensed professionals is equipped with the knowledge and expertise to handle any maintenance task.</p>

                            <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                        </div>
                        <div className="imggdiv col-lg-6 col-12">
                            <img className=" bg-black h-auto" src={photo1} alt="img" />
                        </div>
                    </Box>
                </Box>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <Box className='Boost_about_div'>
                    <Box className="Boost_about_div_home row">
                        <div className="imggdiv col-lg-6 col-12">
                            <img className=" bg-black h-auto" src={photo2} alt="img" />
                        </div>
                        <div className="textdiv col-lg-6 col-12">
                            <h6>Building Maintenance Services Kuwait</h6>
                            <p>Are you searching for a reliable and reputable provider of building maintenance services in Kuwait? Look no further! Boost Empire General Trading & Contracting Co. W.L.L is your trusted partner for all your building maintenance needs. With our unwavering commitment to quality, professionalism, and customer satisfaction, we ensure that your property remains in impeccable condition.</p>

                            <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US TODAY <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                        </div>

                    </Box>
                </Box>
            </Box>


            <div className="SpaceCount_div">
                <h1 className=" fw-bold py-3">Building Maintenance</h1>
                <h1 className=" fw-bold pb-3 fs-4">Requirment Specific Building Maintenance Services</h1>
                <div className="SpaceCount_slidediv">
                    {BuildingMaintenanceServicesData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="SpaceCount_div_slide">
                                {/* <img src={item.imgSrc} alt={`img-${item.id}`} /> */}
                                <h4>{item.heading}</h4>
                                <p>{item.textdetail}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us</h1>

                <div className="row gap-1 Trading_slidediv py-5">
                    <div className=" col-lg-5 col-12 Trading_slide">
                        <h4 style={{ color: '#2764d6' }} className=" text-start fs-1">7+ years of Experience</h4>
                        <p className=" text-start">As an experienced building maintenance services provider in Kuwait, We offer maintenance and repair solutions for various types of buildings.</p>
                        <ul className=" fs-6 fw-bold" style={{ color: '#2764d6' }}>
                            <li>Holistic approach in MEP Contracting</li>
                            <li>Large Team of Professionals</li>
                            <li>Available anywhere in Kuwait</li>
                        </ul>
                        <p className=" text-start mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>

                    </div>
                    <div className="row col-lg-5 col-12">
                        <div className=" col-lg-12 col-12 Trading_slide">
                            <h4 style={{ color: '#2764d6' }} className=" text-start">Expert Team</h4>
                            <p className=" text-start">We have an expert team of Buiding Maintenance</p>
                        </div>
                        <div className=" col-lg-12 col-12 Trading_slide">
                            <h4 style={{ color: '#2764d6' }} className=" text-start">Safety & Security</h4>
                            <p className=" text-start">We work according to compliance of safety and security.</p>
                        </div>

                        <div className=" col-lg-12 col-12 Trading_slide">
                            <h4 style={{ color: '#2764d6' }} className=" text-start">24x7 Availability</h4>
                            <p className=" text-start">Our team of available 24x7 anywhere in Kuwait</p>
                        </div>
                        <div className=" col-lg-12 col-12 Trading_slide">
                            <h4 style={{ color: '#2764d6' }} className=" text-start">Affordable</h4>
                            <p className=" text-start">Our price for building maintenance services is competetive.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" bg-black">
                <GalleryPhotos GalleryData={BuildingMaintenanceWorkImageData} title={""} />
            </div>

            <Box className='Boost_about_div text-start px-5'>
                <h1 className="pt-4 pb-1"> Get a quotation for your building maintenance</h1>
                <p className="pb-1 fs-5">Make Boost Empire General Trading & Contracting Co. W.L.L your partner in building maintenance excellence. Contact us today to discuss your maintenance requirements and schedule a consultation. Experience peace of mind with our reliable and professional building maintenance services in Kuwait.</p>

            </Box>


            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Contact for Building Maintenance Services</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY<MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default BuildingMaintenance