import people1 from '../../assest/BootsEmpire/MechanicalWorkImg2.jpg'
import people2 from '../../assest/BootsEmpire/MechanicalWorkImg6.png'
import people3 from '../../assest/BootsEmpire/MechanicalWorkImg1.jpg'


const ElectroMechanicalEngineeringData = [
    {
        id: 1,
        heading:'Expertise',
        textInfo:'Our team of skilled and experienced mechanical engineers and technicians brings a wealth of knowledge and expertise to every project. We have a deep understanding of mechanical systems and components, allowing us to deliver precise and efficient solutions tailored to your specific requirements.',
        imgSrc: people1,
    },
    
    {
        id: 2,
        heading:'Quality Workmanship',
        textInfo:"We are committed to delivering the highest quality workmanship in all our mechanical works projects. Whether it's HVAC systems, plumbing installations, or equipment maintenance, our meticulous attention to detail ensures that every task is executed to perfection.",
        imgSrc: people2,
    },
    {
        id: 3,
        heading:"Cutting-Edge Technology",
        textInfo:"We stay updated with the latest advancements in mechanical technology to provide you with innovative and efficient solutions. From energy-efficient HVAC systems to state-of-the-art plumbing fixtures, we leverage technology to enhance the performance and sustainability of your mechanical systems.",
        imgSrc: people3,
    }
   
];
export default ElectroMechanicalEngineeringData