
import { Box, Button } from "@mui/material"
import BootsEmpireFooter from "../../common/BootsEmpire/Footer/BootsEmpireFooter"
import BootsEmpireHeader from "../../common/BootsEmpire/header/BootsEmpireHeader"
import photo1 from '../../assest/BootsEmpire/GreaseTrapCleaningBanner.jpg'
import photo2 from '../../assest/BootsEmpire/GreaseTrapCleaningImg1.jpeg'
import photo3 from '../../assest/BootsEmpire/Greasebefore.jpg'
import photo4 from '../../assest/BootsEmpire/Greaseafter.jpg'
import photo5 from '../../assest/BootsEmpire/GreaseTrapCleaningImg5.png'
import photo6 from '../../assest/BootsEmpire/GreaseTrapCleaningImg4.webp'
import { MdChevronRight, MdNotifications } from "react-icons/md"
import { Link } from "react-router-dom"
import GalleryPhotos from "../../common/BootsEmpire/Gallery/Gallery"
import '../../styles/BootsEmpire/CommercialResidentialInteriorStyle.css'
import GreaseTrapCleaningImageData from "../../data/BootsEmpire/GreaseTrapCleaningImageData"



const GreaseTrapCleaning = () => {
    return (
        <>
            <BootsEmpireHeader />
            <Box className='CommercialResidential_topdiv'>
                <img src={photo1} alt="img" />
                <div className="hosp_Residentialhead">
                    <h1 className=" text-center" style={{ textShadow: '30px 30px 30px 30px black' }}>Grease Trap Cleaning</h1>
                </div>
            </Box>


            <Box className='Boost_about_div text-start px-5'>
                <h1 className="text-start fs-2 pb-4">Grease Trapping Cleaning Kuwait</h1>
                <p className="pb-1 fs-5">Grease trap has a critical role to play in any commercial kitchen or food processing plant and assists with grease management by catching toxic greases, fats and oils. It is compulsory for all commercial and industrial food preparation facilities to fix grease traps, either inside or outside. It acts as a strainer and eliminates liquid grease suspensions or food waste by stopping them from flowing in the drainage system where they can lead to possibly damaging blockages.</p>
                <p className="pb-1 fs-5">Being one of the top providers of grease trap cleaning services in Kuwait, Boost Empire General Trading & Contracting cleans your clogged drainage system in order to let your water flow easily. We make sure your grease traps are left clear that would ultimately lessen bad smells and possibility of blockages in the near future.</p>
                <p className="pb-1 fs-5">Our company specializes in grease trap cleaning so as to fulfil public health and safety rules. We also believe that right indoor and outdoor grease trap cleaning and maintenance is a must to help your operations in running smoothly. Our highly skilled experts thus finely rub away grease deposits and double-check whether or not all waste is emitted completely. Through our regular grease trap cleaning services, you can prevent unwanted elements from contaminating your outbound lines and avoid old grease build up that will later turn into expensive emergency issues</p>
            </Box>


            <Box className='Boost_about_div'>

                <Box className="Boost_about_div_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo6} alt="img" />
                        <img className=" bg-black h-auto mt-3" src={photo2} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <h1>Grease Trap Cleaning Services</h1>
                        <p className=" mb-3 pt-0">Our Grease Trap Cleaning and waste removal can be put into execution at any time of day during the week, whichever is convenient to your business. This will guarantee that our grease trap cleaning services do not affect your routine commercial activity.</p>
                        <p className=" mb-3 pt-0">Our professional grease trap cleaning services in Kuwait comprises of evacuating and cleaning the complete contents of the unit which has been accumulated during everyday use, examination of the drain runs along with your drainage systems, elimination from site and eco-friendly disposal of the grease trap waste.</p>
                        <p className=" mb-3 pt-0">Since we are a leading provider of grease trap cleaning services in Kuwait, you can be assured that we strictly follow the norms set by the industry. Our professionals will habitually examine your grease traps and make sure they are working appropriately to help avert clogs and overflows. </p>

                        <Link to={'/contact_us'}> <Button className="morebtn">CONTACT US <MdChevronRight className=" ms-3 fs-4" /></Button></Link>
                    </div>

                </Box>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <h1>Our Specialties</h1>
                <p className="pt-4 pb-1 fs-4">You can expect the following from our grease trap cleaning services:</p>
                <ol className=" fs-5">
                    <li className="mb-3">Regularly Planned Maintenance:Our grease trap cleaning experts will come at a scheduled time to make sure that your grease traps are properly cleaned and timely maintenance is done.</li>
                    <li className="mb-3">Grease Trap Check-Up:We will systematically check both inside and outside of grease traps and conduct cleaning accordingly. We effectively & professionally pump, vacuum or haul the fluid waste and mud from grease traps.</li>
                    <li className="mb-3">Rodding & Jetting:We have the best suitable equipment to push out clogged areas and make sure that all build-up and debris is extracted.</li>
                    <li className="mb-3">Inspection Report:We will offer you with a complete report each time we clean out your grease traps.</li>
                    <li className="mb-3">History Reports: We will carefully record the history of your grease trap cleaning & maintenance services and keep a track of all past issues and help identify potential problems.</li>
                    <li className="mb-3">Emergency 24/7 Clean-up:We are available 24/7 to clean and maintain grease traps in an emergency situation.</li>
                    <li className="mb-3">Smooth & Convenient Service: You can avail reliable, efficient, hygienic and cost-effective grease trap cleaning & maintenance.</li>
                </ol>
            </Box>

            <div className=" row justify-content-center align-items-center my-5 ">
                <div className=" col-lg-5 col-12">
                    <h1 className=" fw-bold fs-3 text-center">Before</h1>
                    <img className=" bg-black w-100" src={photo3} alt="img" />
                </div>
                <div className=" col-lg-5 col-12">
                    <h1 className=" fw-bold fs-3 text-center">After</h1>
                    <img className=" bg-black w-100" src={photo4} alt="img" />
                </div>

            </div>


            <Box className='Boost_about_div'>
                <Box className="Boost_about_div_home row">

                    <div className="textdiv col-lg-6 col-12">
                        <h1>Who Do We Work With?</h1>
                        <p className=" mb-3 pt-0">We work with businesses which run a commercial kitchen and produce excess waste and need to dispose of it economically and in eco-friendly manner. These business sectors include:</p>
                        <ul>
                            <li>Pubs</li>
                            <li>Restaurants</li>
                            <li>Hotels</li>
                            <li>Takeaway restaurants</li>
                            <li>Fast food outlets</li>
                        </ul>
                        {/* <Link to={''}> <Button className="morebtn">CONTACT US <MdChevronRight className=" ms-3 fs-4" /></Button></Link> */}
                    </div>

                    <div className="imggdiv col-lg-6 col-12">
                        <img className=" bg-black h-auto" src={photo5} alt="img" />
                    </div>

                </Box>
            </Box>

            <Box className='Boost_about_div text-start px-5'>
                <h1>Save Your Employees From The Hassle</h1>
                <p className=" fs-5"> Keeping your employees pleased and healthy is a good way to guarantee that they’ll do their finest job for you. A part of that comprise of not compelling kitchen workers to execute difficult plumbing tasks, or work in a kitchen that endangers their health and protection. Further, every employee seeks to have a manager or business owner who lets them lay emphasis on what they do best, be it cooking and serving food, wash dishes or offering customer service—and who lets the team at Boost Empire General Trading & Contracting take care of the challenging work</p>

                <h1>The Benefits of Maintaining Grease Traps Regularly</h1>
                <p className=" fs-5"> According to survey, both indoor and outdoor grease traps need cleaning in every 30 – 90 days. However, not all grease traps need cleaning at the similar interval. The frequency of grease trap cleaning required would vary depending on various parameters such as:</p>

                <ul>
                    <li>The size of business</li>
                    <li>The type of food prepared and,</li>
                    <li>The capacity of the grease trap</li>
                </ul>
            </Box>



            <div className="TradingCompany_div">
                <h1 className=" fw-bold mx-5">Why Choose Us?</h1>
                <p className=" text-white mx-5 px-5 mb-0" style={{ letterSpacing: '1px' }}> Our skilled team of engineers, who boast more than a decade of experience in this field, ensures the best possible service for our clients. We take pride in our high-quality results, which will help you build confidence in your project and its importance.</p>
            </div>

            <div className=" bg-black">
                <GalleryPhotos GalleryData={GreaseTrapCleaningImageData} title={""} />
            </div>

            <Box className='Boost_about_div text-start px-5'>
                <ul className=" text-white">
                    <li>We conduct research and develop advanced solutions specifically for the food service industry and endow our clientele with best grease trap cleaning services in Kuwait.</li>
                    <li>We let your grease trap system operate at optimum levels for longer duration by lessening the build-up of FOG (Fats, Oil & Grease).</li>
                    <li>We help in keeping the environment clean by stopping the seepage of FOG into the local water lines.</li>
                    <li>We avert your business from momentary closure led by malodours or back-ups.</li>
                    <li>We work on keeping the sewage lines clean, thus eradicating the want to pay penalties to the concerned authorities for non-maintenance of your grease traps.</li>
                    <li>We avoid acquiring high expenses for restoring your grease trap system as maintaining the system is generally inexpensive and hassle-free.</li>
                </ul>
                <p className=" text-white mb-0" style={{ letterSpacing: '1px' }}> If you require any sort of advice on grease trap cleaning, installations or maintenance in Kuwait, then do get in touch with us and we will be more than pleased to talk with you.</p>
            </Box>


            <div className=" d-flex align-items-center justify-content-between p-5 bg-black">
                <h1 className=" fw-bold w-75 text-white">Contact us for any cleaning requirment in Kuwait</h1>
                <Link to={'/contact_us'}><Button className=" bg-primary text-white fw-bold px-4 py-3">CONTACT US TODAY <MdChevronRight className=" ms-2 fs-4" /></Button></Link>
            </div>
            <BootsEmpireFooter />

        </>
    )
}

export default GreaseTrapCleaning