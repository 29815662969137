

import comingsoon1 from '../../assest/BootsEmpire/KitchenDustCleaningImg1.png'
import comingsoon2 from '../../assest/BootsEmpire/KitchenDustCleaningImg2.jpg'
import comingsoon3 from '../../assest/BootsEmpire/KitchenDustCleaningImg3.jpg'
import comingsoon4 from '../../assest/BootsEmpire/KitchenDustCleaningImg4.jpg'
import comingsoon5 from '../../assest/BootsEmpire/KitchenDustCleaningImg5.jpg'
import comingsoon6 from '../../assest/BootsEmpire/KitchenDustCleaningImg6.jpg'
import comingsoon7 from '../../assest/BootsEmpire/KitchenDustCleaningImg7.jpg'
import comingsoon8 from '../../assest/BootsEmpire/KitchenDustCleaningImg11.jpg'
import comingsoon9 from '../../assest/BootsEmpire/KitchenDustCleaningImg9.jpg'
import comingsoon10 from '../../assest/BootsEmpire/KitchenDustCleaningImg10.jpg'





const KitchenDuctCleaningImageData = [
    {
        id: 1,
        src: comingsoon4,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon3,
    }, {
        id: 4,
        src: comingsoon5,
    }
    , {
        id: 5,
        src: comingsoon1,
    },
    {
        id: 6,
        src: comingsoon6,
    }
    , {
        id: 7,
        src: comingsoon7,
    },
    {
        id: 8,
        src: comingsoon8,
    },
    {
        id: 9,
        src: comingsoon9,
    }
    , {
        id: 10,
        src: comingsoon10,
    }
   
];
export default KitchenDuctCleaningImageData;