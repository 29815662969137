
const WaterproofingSolutionsData = [
    {
        id: 1,
        heading: " Customized Water proofing Solutions",
        textdetail: "We recognize that every building is unique, and its waterproofing requirements may vary. Our team works closely with you to understand your specific needs and design tailored waterproofing systems that address your building’s vulnerabilities. From roof waterproofing to basement waterproofing and everything in between, we have you covered.",
    },

    {
        id: 2,
         heading: " High Quality Materials",
        textdetail: "At Boost Empire General Trading & Contracting co., we believe in using only the highest quality materials for our waterproofing projects. We partner with reputable suppliers to source premium waterproofing membranes, coatings, sealants, and other materials. This ensures that our solutions offer maximum durability, longevity, and protection against water intrusion.", 
    },
    {
        id: 3,
         heading: " Timely Project Completion",
        textdetail: "We understand that time is of the essence when it comes to waterproofing. Water damage can escalate quickly, leading to costly repairs and disruptions. That’s why we prioritize efficient project completion without compromising on quality. Our team works diligently to ensure that your waterproofing project is completed within the agreed-upon timeframe.", 
    },
    {
        id: 4,
         heading: " Expert Workmanship",
        textdetail: "Our team comprises skilled technicians who are experts in their craft. They undergo regular training to stay up-to-date with the latest waterproofing techniques and advancements. When you choose Boost Empire General Trading & Contracting, you can rest assured that your project will be executed with precision, professionalism, and a keen eye for detail.", 
    },
    {
        id: 5,
         heading: " Comprehensive Waterproofing Services",
        textdetail: "We offer a wide range of waterproofing services to safeguard different areas of your property. Our expertise covers roof waterproofing, terrace waterproofing, bathroom waterproofing, foundation waterproofing, swimming pool waterproofing, and more. No matter the size or complexity of the project, we have the capabilities to deliver exceptional results.", 
    },
    {
        id: 6,
         heading: " Extensive Expertise",
        textdetail: "With years of experience in the industry, we have established ourselves as a leading provider of waterproofing services in Kuwait. Our skilled professionals possess comprehensive knowledge of waterproofing techniques, materials, and industry best practices. You can trust us to deliver effective solutions for even the most complex waterproofing challenges.", 
    },

];
export default WaterproofingSolutionsData